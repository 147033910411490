import React, { Component, createContext } from "react";
import { auth } from "./firebase";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    role: null,
    userDataPresent: false,
    user: null
  };

  componentDidMount = () => {
    const setUserRole = (role) => {
      this.setState({ role: role });
    }
    const setUserOrganisation = (org) => {
      this.setState({ org: org });
    }

    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        userAuth.getIdTokenResult().then(idTokenResult => {
          Object.keys(idTokenResult.claims).forEach(function (key) {
            if (key.includes('ROLE_')) {
              setUserRole(key);
            }
            if (key.includes('org_')) {
              setUserOrganisation(key);
            }
          });
        });
        this.setState({ user: userAuth });
        this.setState({ userDataPresent: true });
      } else {
        this.setState({ user: null});
        this.setState({ userDataPresent: true});
      }
    });
  };
  render() {
    return (
        <UserContext.Provider value={this.state}>
          {this.props.children}
        </UserContext.Provider>
    );
  }
}
export default UserProvider;