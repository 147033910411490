import {createTheme} from "@mui/material";

const appTheme = createTheme({
  palette: {
    primary: {
      main: '#002C76',
      light: '#eda19b',
      dark: '#49110d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#eb680a',
      light: '#e9eef2',
      dark: '#eb680a',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
      secondary: '#000',
      disabled: '',
    },
    background: {
      default: '#fff',
      paper: '#fff',
      disabled: '#eef2f5',
    },
    common: {
      black: '#000',
      white: '#fff',
      gray: '#808080',
    },
    navbar: {
      main: '#2f4857',
      text: '#fff',
      activeText: '#68edcb',
    },
    session: {
      active: '#ff961c',
      inactive: '#86bc25',
    },
    status: {
      online: '#98d827',
      offline: '#FF5050',
    },
    brabs: {
      main: '#e5eff1',
    },
    lifecycleState: {
      pending: '#ebc05b',
      ready: '#6ccde5',
      operative: '#8beacd',
      out_of_order: '#ed5d58',
      inactive: '#276273',
    },
    actions: {
      main: '#68edcb',
      hover: '#4ead95',
    },
    datePicker: {
      primary: '#016375',
      saturday: '#ffa6a6',
    },
  },
  typography: {
    fontFamily: '"Roboto","Arial", sans-serif',
    fontWeight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 12,
    h1: {
      fontSize: '2.2rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      margin: '0px 0px 8px',
    },
    body2: {
      fontSize: '0.85rem',
      margin: '0px 0px 8px',
    },
  },
  general: {
    maxWidth: '1900px',
  },
});

const overrides = baseTheme => ({
  MuiInputBase: {
    root: {
      fontSize: '1rem',
      color: baseTheme.palette.text.primary,
    },
    input: {
      fontSize: '1rem',
      height: '1.6rem',
      lineHeight: 1,
      '&::placeholder': {
        color: baseTheme.palette.primary.light,
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      '&:hover $notchedOutline': {
        borderColor: baseTheme.palette.secondary.dark,
      },
      '&$focused $notchedOutline': {
        borderColor: baseTheme.palette.secondary.dark,
        borderWidth: 1,
      },
    },
    notchedOutline: {
      borderColor: baseTheme.palette.secondary.main,
    },
  },
  MuiFab: {
    root: {
      boxShadow: 'none',
    },
  },
  MuiChip: {
    root: {
      fontSize: baseTheme.typography.body2.fontSize,
      color: baseTheme.palette.secondary.dark,
      backgroundColor: baseTheme.palette.background.paper,
      height: 26,
    },
    outlined: {
      border: `1px solid ${baseTheme.palette.secondary.dark}`,
      backgroundColor: 'transparent',
    },
    label: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: baseTheme.spacing(0, 2),
      '& > svg': {
        marginRight: baseTheme.spacing(1),
        fontSize: '1.4em',
      },
    },
    deleteIcon: {
      width: 12,
      height: 12,
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: 'none',
    },
  },
  MuiButton: {
    root: {
      fontSize: 20
    }
  },
  MuiExpansionPanel: {
    root: {
      backgroundColor: baseTheme.palette.background.default,
      borderTop: `1px solid ${baseTheme.palette.primary.main}`,
      transition: baseTheme.transitions.create(['border', 'background-color'], {
        easing: baseTheme.transitions.easing.easeInOut,
        duration: baseTheme.transitions.duration.fast,
      }),
      '&:last-of-type': {
        borderBottom: `1px solid ${baseTheme.palette.primary.main}`,
      },
      '&$expanded': {
        backgroundColor: baseTheme.palette.background.paper,
        borderTop: `2px solid ${baseTheme.palette.primary.main}`,
        margin: 0,
      },
    },
  },
  MuiExpansionPanelSummary: {
    root: {
      padding: baseTheme.spacing(0, 2),
      minHeight: 40,
      '&$expanded': {
        minHeight: 40,
      },
    },
    content: {
      color: baseTheme.palette.primary.light,
      margin: baseTheme.spacing(2, 0),
      '&$expanded': {
        color: baseTheme.palette.primary.main,
        margin: baseTheme.spacing(2, 0),
      },
    },
    expandIcon: {
      color: baseTheme.palette.primary.main,
      padding: baseTheme.spacing(1, 1),
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      padding: baseTheme.spacing(1.5, 2, 3),
    },
  },
  MuiTableCell: {
    root: {
      fontSize: '1rem',
      borderBottom: 'none',
    },
    head: {
      color: baseTheme.palette.primary.light,
      paddingBottom: '10px',
    },
    body: {
      color: baseTheme.palette.text.secondary,
      backgroundColor: baseTheme.palette.common.white,

      '&:first-child': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },

      '&:last-child': {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      },
    },
  },
  MuiTableRow: {
    root: {
      minHeight: baseTheme.spacing(9),
    },
    head: {
      backgroundColor: 'transparent',
      color: baseTheme.palette.primary.light,
    },
  },
  MuiTableSortLabel: {
    root: {
      '&$active': {
        color: baseTheme.palette.primary.light,
      },
    },
    icon: {
      height: '0.7em',
    },
  },
  MuiCheckbox: {
    colorSecondary: {
      '&$checked': {
        color: baseTheme.palette.primary.main,
      },
    },
  },
  MuiTable: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: baseTheme.spacing(0, 1),
    },
  },
  MuiTablePagination: {
    caption: {
      fontSize: baseTheme.typography.htmlFontSize,
      margin: 0,
    },
    select: {
      fontSize: baseTheme.typography.htmlFontSize,
    },
    selectIcon: {
      top: 'initial',
    },
  },
  MuiSelect: {
    outlined: {
      ...baseTheme.typography.h2,
      border: `1px solid ${baseTheme.palette.primary.main}`,
      borderRadius: 30,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center !important', // that's hacky but we need it
      height: 'auto !important', // that's hacky but we need it
      '& > .MuiSvgIcon-root': {
        width: '0.7em',
      },
      '&$select:focus': {
        borderRadius: 30,
        backgroundColor: 'inherit',
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'inherit',
      },
    },
    selectMenu: {
      '&.MuiSelect-selectMenu.MuiSelect-selectMenu': {
        height: '1.6rem',
        display: 'inline-flex',
        alignItems: 'flex-end',
        paddingRight: baseTheme.spacing(4),
      },
    },
  },
  MuiMenu: {
    paper: {
      backgroundColor: baseTheme.palette.background.default,
    },
  },
  MuiMenuItem: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > .MuiSvgIcon-root': {
        width: '0.7em',
      },
    },
  },
  MuiDialog: {
    paper: {
      backgroundColor: baseTheme.palette.background.white,
    },
  },
  MuiDialogTitle: {
    root: {
      fontSize: 28,
      textAlign: 'center',
      padding: baseTheme.spacing(4, 3, 2),
      [baseTheme.breakpoints.up('sm')]: {
        padding: baseTheme.spacing(5, 3, 3),
      },
    },
  },
  MuiDialogContent: {
    root: {
      margin: baseTheme.spacing(0),
      padding: baseTheme.spacing(0, 3, 1),
      [baseTheme.breakpoints.up('sm')]: {
        margin: baseTheme.spacing(0, 8, 1),
      },
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'center',
      margin: baseTheme.spacing(1, 2, 2),
      [baseTheme.breakpoints.up('sm')]: {
        margin: baseTheme.spacing(1, 4, 2),
      },
    },
    spacing: {
      '&> :not(:first-child)': {
        marginLeft: baseTheme.spacing(3),
      },
    },
  },
  MuiFormGroup: {
    root: {
      position: 'relative',
    },
  },
  MuiFormHelperText: {
    root: {
      position: 'absolute',
      top: '100%',
      lineHeight: 1,
    },
  },
  MuiFormControlLabel: {
    label: {
      margin: 0,
    },
  },
  MuiDivider: {
    light: {
      backgroundColor: baseTheme.palette.common.white,
    },
  },
  MuiStepper: {
    horizontal: {
      backgroundColor: 'transparent',
      padding: baseTheme.spacing(0, 3),
    },
  },
  MuiStep: {
    horizontal: {
      minHeight: baseTheme.spacing(3),
    },
    completed: {
      padding: 0,
    },
  },
  MuiStepLabel: {
    iconContainer: {
      paddingRight: 0,
    },
  },
  MuiAutocomplete: {
    paper: {
      background: baseTheme.palette.background.default,
      boxShadow: baseTheme.shadows[1],
    },
  },
});

export default createTheme({
  ...appTheme,
  overrides: overrides(appTheme),
});