import React, {useEffect, useRef} from 'react';
import {withStyles} from "@mui/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {getOrganisations} from "../../utils/api";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ChangeOrganisationIcon from "@mui/icons-material/Settings";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function ChangeUserDialog(props) {
  const { classes, confirmationHandler, user } = props;
  const [organisation, setOrganisation] = React.useState({ id: 0, title: 'Global'});
  const [availableOrganisations, setAvailableOrganisations] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const cancelRequest = useRef();

  const fetchData =
      async () => {
        try {
          const result = await getOrganisations();
          const organisationData = result.data;

          if (!cancelRequest.current) {
            if (organisationData && Object.keys(organisationData).length > 0) {
              setAvailableOrganisations(organisationData);
            }
          }
        } catch (error) {
        }
      };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOrganisation('0');
  };

  const handleConfirm = () => {
    confirmationHandler(user.id, user.uid, organisation);
    handleClose();
  };

  const handleOrganisationChange = (event) => {
    setOrganisation(availableOrganisations.find(p => p.id === event.target.value));
  };

  return (
      <div>
        <Tooltip title="Change organisation for user">
          <IconButton onClick={handleClickOpen}>
            <ChangeOrganisationIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change organisation for {user.email}</DialogTitle>
          <DialogContent>
            <DescriptionList>
              <DescriptionTerm>Organisation</DescriptionTerm>
              <Description>
                <Select
                    labelId="organisation-select"
                    id="organisation-select"
                    value={organisation ? organisation.id + '' : '1'}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={handleOrganisationChange}
                >
                  {availableOrganisations.length > 0 && availableOrganisations.map((p) => (
                      <MenuItem value={p.id}>{p.name}</MenuItem>
                  ))}
                </Select>
              </Description>
            </DescriptionList>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(ChangeUserDialog)