import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import useInput from '../../utils/useInput';

import { EditableComponent, paddingForInput } from './EditableInput.parts';
import AcceptRejectButtons from './AcceptRejectButtons';
import {OutlinedInput} from "@mui/material";

const InputField = styled(OutlinedInput)`
  > input {
    height: auto;
    font-size: inherit;
    text-transform: inherit;
    padding: ${props => paddingForInput(props)};
  }
`;

const Postfix = styled.span`
  margin-left: 2px;
`;

const Prefix = styled.span`
  margin-right: 2px;
`;

const InputEdit = ({ fullWidth, multiline, rows, inputProps, prefix, postfix, name, size, type, value, onClose, onSave }) => {
  const { value: inputValue, bind } = useInput(value);

  const handleKeyListener = useCallback(
      e => {
        if (e.key === 'Enter') {
          onSave(name, inputValue);
          onClose();
        }
        if (e.key === 'Escape') {
          onClose();
        }
      },
      [onSave, name, inputValue, onClose]
  );

  return (
      <EditableComponent fullWidth={fullWidth}>
        <Prefix>{prefix}</Prefix>
        <InputField
            autoFocus={true}
            inputProps={{
              ...inputProps,
              'data-testid': 'editable-input',
            }}
            name={name}
            size={size}
            type={type}
            variant="outlined"
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            onKeyDown={handleKeyListener}
            data-testid="editable-field"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bind}
        />
        <Postfix>{postfix}</Postfix>
        <AcceptRejectButtons
            floating={true}
            onAccept={() => {
              onSave(name, inputValue);
              onClose();
            }}
            onReject={() => {
              onClose();
            }}
        />
      </EditableComponent>
  );
};

InputEdit.defaultProps = {
  fullWidth: false,
  inputProps: {},
  postfix: '',
  prefix: '',
  size: 'large',
  type: 'string',
};

InputEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  postfix: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default InputEdit;