import React, {useState} from 'react';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";


const styles = () => ({
  block: {
    display: 'block',
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

function DataGridActionBar(props) {
  const { classes, data, confirmationHandler, hideEdit } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteConfirm = () => {
    confirmationHandler(data.uid, 'HARD');
    handleClose();
  };

  return (
      <>
        { !hideEdit &&
        <LightTooltip title="Edit">
          <IconButton aria-label="edit">
            <EditIcon />
          </IconButton>
        </LightTooltip>
        }
        <LightTooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleClickOpen}>
            <DeleteIcon />
          </IconButton>
        </LightTooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Supprimer l'utilisateur</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>
                Êtes-vous sûr ?
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary">
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(DataGridActionBar)