import React from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function ConfirmActionDialog(props) {
  const { classes, confirmationHandler, action } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmationHandler();
    handleClose();
  };

  return (
      <>
        { action === 'delete' &&
        <Tooltip title="Delete">
          <IconButton  onClick={handleClickOpen}>
            <DeleteIcon sx={{ fontSize: 24 }} className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          { action === 'delete' &&
          <DialogTitle id="form-dialog-title">Delete activtiy</DialogTitle>
          }
          <DialogContent>
            { action === 'delete' &&
            <DialogContentText>Are you sure?</DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

export default withStyles(styles)(ConfirmActionDialog)