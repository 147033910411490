import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import {UserContext} from "./auth/UserProvider";
import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import {auth} from "./auth/firebase";
import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip, Typography
} from "@mui/material";
import {withStyles} from "@mui/styles";

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const { classes, onDrawerToggle, headline } = props;

  const authState = useContext(UserContext);

  return (
      <React.Fragment>
        <AppBar color={'primary'} position="sticky" elevation={0}>
          <Toolbar>
            <Grid container spacing={8} alignItems="center">
              <Hidden smUp>
                <Grid item>
                  <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={onDrawerToggle}
                      className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              {
                authState && authState.user &&
                <>
                  <Grid item xs />
                  <Grid item>
                    <Tooltip title="Sign out">
                      <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={() => {auth.signOut()}}>
                        <PowerIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              }
            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar
            component="div"
            className={classes.secondaryBar}
            color={'primary'}
            position="static"
            elevation={0}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <Typography color="inherit" variant="h1" component="h1">
                  {headline}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);