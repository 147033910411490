import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {InputAdornment, OutlinedInput, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {getOrganisations, searchOutputIndicators} from "../../utils/api";
import {DescriptionList, DescriptionTerm, Description} from "../parts/Description.parts";

export default function AddBudgetValueDialog(props) {
  const { budget, entity, confirmationHandler, action = 'create', selectedYear } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [riskValue, setRiskValue] = useState('');
  const [quarter, setQuarter] = useState('3');
  const [year, setYear] = useState(new Date().getFullYear());
  const [outputActivityOptions, setOutputActivityOptions] = useState([]);
  const [organisation, setOrganisation] = React.useState(undefined);
  const [availableOrganisations, setAvailableOrganisations] = React.useState([]);

  const cancelRequest = useRef();

  const fetchData =
      async () => {
        try {
          const result = await getOrganisations();
          const orgData = result.data;

          if (!cancelRequest.current) {
            if (orgData && Object.keys(orgData).length > 0) {
              setOrganisation(orgData[0]);
              setAvailableOrganisations(orgData);
            }
          }
        } catch (error) {
        }
      };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setRiskValue(entity && entity.riskValue ? entity.riskValue : '');
    setYear(entity && entity.attributionDate ? new Date(entity.attributionDate).getFullYear() : new Date().getFullYear());
    setQuarter(entity && entity.attributionDate ? (new Date(entity.attributionDate).getMonth() + 1) + '' : '3');
    setOrganisation(entity && entity.organisation ? entity.organisation : undefined);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    let parsedQuarter = parseInt(quarter);
    if (parsedQuarter < 10) {
      parsedQuarter = '0' + quarter;
    }

    let attributionDate =
        new Date(`${year !== '' ? year : 2021}-${parsedQuarter}-01`)
            .toISOString().split('T')[0];

    action === 'create' ?
        confirmationHandler(
            budget.id,
            attributionDate, indicatorValueDescription, riskValue, organisation ? organisation : availableOrganisations[0]) :
        confirmationHandler(
            entity.id,
            budget.id,
            attributionDate, indicatorValueDescription, riskValue, organisation ? organisation : availableOrganisations[0]);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setQuarter('3');
    setYear('');
    setRiskValue('');
    setOrganisation(availableOrganisations[0]);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onRiskValueChange = (e) => {
    setRiskValue(e.target.value);
  };

  const handleOrganisationChange = (event) => {
    setOrganisation(availableOrganisations.find(p => p.id === event.target.value));
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      const response = await searchOutputIndicators('');

      if (active) {
        if (response.data) {
          setOutputActivityOptions(response.data);
        } else {
          setOutputActivityOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  return (
      <div>
        {action === 'create' &&
            <Button size="small" variant="contained" color="secondary" onClick={handleClickOpen}>
              Add budget value
            </Button>
        }
        { action === 'edit' &&
            <Tooltip title="Edit">
              <IconButton aria-label="edit"
                          onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add new' : 'Edit'} budget value</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill in the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            <FormControl variant="outlined" style={{ marginTop: '1rem', marginRight: '1rem', width: '15rem'}}>
              <InputLabel id="quarter-select-label">
                Quarter
              </InputLabel>
              <Select
                  labelId="quarter-select-label"
                  id="quarter-select"
                  label="Quarter"
                  value={quarter}
                  onChange={(e) => setQuarter(e.target.value)}
                  variant={'outlined'}
                  fullWidth
              >
                <MenuItem value={'3'}>1st</MenuItem>
                <MenuItem value={'6'}>2nd</MenuItem>
                <MenuItem value={'9'}>3rd</MenuItem>
                <MenuItem value={'12'}>4th</MenuItem>
              </Select>
            </FormControl>
            <TextField
                InputLabelProps={{ shrink: true }}
                margin="normal"
                id="males"
                label="Year"
                type="number"
                value={selectedYear ? selectedYear : year}
                onChange={(e) => setYear(e.target.value)}
                variant="outlined"
            />
            <DescriptionList>
              <DescriptionTerm>Organisation</DescriptionTerm>
              <Description>
                <Select
                    labelId="org-select"
                    id="org-select"
                    value={organisation ? organisation.id + '' : '1'}
                    variant="outlined"
                    onChange={handleOrganisationChange}
                >
                  {availableOrganisations.length > 0 && availableOrganisations.map((p) => (
                      <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                  ))}
                </Select>
              </Description>
            </DescriptionList>
            <br/>
            <FormControl fullWidth sx={{ m: 1, marginLeft: 0 }}>
              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
              <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start">€</InputAdornment>}
                  label="Amount"
                  value={riskValue}
                  onChange={onRiskValueChange}
                  type="number"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              { action === 'create' ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}