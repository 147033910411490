import React, {useContext, useEffect, useRef, useState} from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {
  addFollowUpSurveyEntry, addSurveyEntry,
  addTargetValue, getActivity, searchLocations, searchOrganisations,
  updateActivity
} from "../../utils/api";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import {formatTime} from '../../utils/timeHelper';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import EditableInput from "../input/EditableInput";
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import {CircularProgress} from "@mui/material";
import {
  ImageList,
} from "@mui/material";
import {useParams} from "react-router-dom";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  contentWrapper: {
    margin: 'auto'
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function Activity() {
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [distributionValue, setDistributionValue] = React.useState(1);
  const [improvementValue, setImprovementValue] = React.useState(1);
  const [stateActivity, setStateActivity] = useState(null);
  const [activityBuffer, setActivityBuffer] = useState(null);

  let {activityId} = useParams();

  const authState = useContext(UserContext);

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getActivity(activityId);
          const activityData = result.data;

          if (!cancelRequest.current) {
            if (activityData) {
              setIsLoading(false);
              setStateActivity(activityData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  const outputs = [
    { name: 'Output 1', value: 'OUTPUT_1', description: '- Les capacités des acteurs en vue de mettre en œuvre des mesures visant à promouvoir '
          + 'la durabilité environnementale, économique et sociale dans et autour '
          + 'du secteur minier sont améliorées.' },
    { name: 'Output 2', value: 'OUTPUT_2', description: '- Les provinces du Haut-Katanga et du Lualaba ont progressé dans la création de sources'
          + ' de revenus respectueuses de l’environnement et des droits humains pour'
          + ' les mineur·euse·s artisa-naux·ales.' },
    { name: 'Output 3', value: 'OUTPUT_3', description: '- Les apprenti·e·s et les personnes intéressées par le travail indépendant des communautés'
          + ' minières du Haut-Katanga et du Lualaba utilisent des offres de qualification'
          + ' améliorées et axées sur la pratique.' },
    { name: 'Output 4', value: 'OUTPUT_4', description: '- Les capacités d’un recouvrement des impôts plus efficace des entreprises minières'
          + ' ont été améliorées au sein de l’administration fiscale congolaise.' },
  ];

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          stateActivity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData(true);
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !stateActivity) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="primary"/>
            </div>
          </>
      );
    }

    return <React.Fragment>
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <AppBar className={classes.toolbar}
                      position="static"
                      color="default"
                      elevation={0}>
                <Toolbar>
                  <Typography variant="h6" gutterBottom component="div">
                    General information
                  </Typography>
                </Toolbar>
              </AppBar>
              <div className={classes.contentWrapper}>
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Type</DescriptionTerm>
                    <Description>
                      <Select
                          labelId="activity-type-select"
                          id="activity-type-select"
                          value={stateActivity.activityType}
                          className={classes.selectEmpty}
                          onChange={handleActivityTypeChange}
                      >
                        <MenuItem value={'WORKSHOP'}>Workshop</MenuItem>
                        <MenuItem value={'MEETING'}>Meeting</MenuItem>
                        <MenuItem value={'CAMPAIGN'}>Campaign</MenuItem>
                      </Select>
                    </Description>
                  </DescriptionList>
                </ImageList>
                <ImageList cellHeight={'auto'} className={classes.gridList}
                           cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Name</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="name"
                          value={stateActivity.name}
                          disabled={false}
                          fullWidth={true}
                          size={'large'}
                          multiline={true}
                          rows={4}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                  <br/>
                  <DescriptionList>
                    <DescriptionTerm>Location</DescriptionTerm>
                    <Description>
                      <BasicAutocomplete
                          handleChange={(value) => updateActivityDataAndDispatch(
                              'location', value)} searchFct={searchLocations}
                          currentValue={stateActivity.location}
                          property={'englishName'}/>
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Organisation</DescriptionTerm>
                    <Description>
                      <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('organisation', value)} searchFct={searchOrganisations} currentValue={stateActivity.organisation} property={'name'} />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Region of the Activity</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="region"
                          value={stateActivity.region}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Country</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="country"
                          value={stateActivity.country}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Start date</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="startDate"
                          type="date"
                          value={formatTime(stateActivity.startDate, 'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>End date</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="endDate"
                          type="date"
                          value={formatTime(stateActivity.endDate,
                              'dd.LL.yyyy')}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value + ' 00:00:00.000')}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Responsible</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="responsible"
                          value={stateActivity.responsible}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                      />
                    </Description>
                  </DescriptionList>
                  <DescriptionList>
                    <DescriptionTerm>Comments</DescriptionTerm>
                    <Description>
                      <EditableInput
                          name="comment"
                          value={stateActivity.comment}
                          disabled={false}
                          fullWidth={true}
                          onSave={(fieldName,
                              value) => updateActivityDataAndDispatch(fieldName,
                              value)}
                      />
                    </Description>
                  </DescriptionList>
                </ImageList>
              </div>
            </Paper>
    </React.Fragment>
  }

  return renderData();

}

export default withStyles(styles)(Activity);