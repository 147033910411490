import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import {
  addIndicatorValue,
  deleteIndicatorValue,
  getIndicators, updateIndicatorValue
} from "../../utils/api";
import {Chip, CircularProgress} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {formatTime} from "../../utils/timeHelper";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddIndicatorValueDialog from "../dialogs/AddIndicatorValueDialog";
import CompleteIcon from "@mui/icons-material/Verified";
import IncompleteIcon from "@mui/icons-material/Cancel";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const arrSum = arr => arr.reduce((a, b) => a + b.numericValue, 0);
const arrSumSecondary = arr => arr.filter(v => v.secondaryNumericValue !== undefined).reduce((a, b) => a + b.secondaryNumericValue, 0);
const arrSumTertiary = arr => arr.filter(v => v.tertiaryNumericValue !== undefined).reduce((a, b) => a + b.tertiaryNumericValue, 0);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <div>{children}</div>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 24}} color={'secondary'} variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {props.current} / {props.target}
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function OutputIndicatorPaper(props) {
  const { classes, indicator, updateHandler, confirmationHandler, removeValueHandler, removeHandler, indicatorType, updateValueHandler } = props;

  const [organisation, setOrganisation] = useState('');

  const [open, setOpen] = useState(false);

  const handleOrganisationClicked = (e) => {
    if (organisation === e.currentTarget.innerText) {
      setOrganisation('');
    } else {
      setOrganisation(e.currentTarget.innerText);
    }
  }

  const checkOrganisationFilter = (iVal) => {
    if (organisation === '') return true;
    return (iVal.organisation && iVal.organisation.name === organisation);
  }

  const collapseComponent = (props) => (
      <td colSpan={8}>
        <div className={props.className}>
          {props.children}
        </div>
      </td>
  )

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h2" gutterBottom component="div">
          {indicator.name}
        </Typography>
        <div style={{position: 'absolute', right: '1rem', top: '0.2rem', display: 'flex', flexDirection: 'row'}}>
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.description}
      </Typography>
    </div>
    { indicator.targetValueNumeric === 1 && arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)) === 1 &&
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '16px 16px',
        color: 'green'
      }}>
        <span>Indicator complete</span>
        <CompleteIcon sx={{marginLeft: '0.5rem'}} />
      </div>
    }
    { indicator.targetValueNumeric === 1 && arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)) !== 1 &&
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '16px 16px',
        color: 'orange'
      }}>
        <span>Indicator is not yet complete</span>
        <IncompleteIcon sx={{marginLeft: '0.5rem'}} />
      </div>
    }
    { indicator.targetValueNumeric !== 1 &&
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary">
          {indicator.targetValueDescription}
        </Typography>
        <StyledLinearProgress
            value={Math.round((arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)))
                / (indicator.targetValueNumeric - indicator.startValueNumeric)
                * 100)}
            current={arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)).toLocaleString()}
            target={(indicator.targetValueNumeric - indicator.startValueNumeric).toLocaleString()}/>
      </div>
    }
    { indicator.targetValueNumeric !== 1 && indicator.secondaryTargetValueNumeric > 0 && indicator.secondaryStartValueNumeric >= 0 &&
      <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.secondaryTargetValueDescription}
      </Typography>
      <StyledLinearProgress
      value={Math.round((arrSumSecondary(indicator.indicatorValues.filter(checkOrganisationFilter))) / (indicator.secondaryTargetValueNumeric - indicator.secondaryStartValueNumeric) * 100)}
      current={arrSumSecondary(indicator.indicatorValues.filter(checkOrganisationFilter)).toLocaleString()}
      target={(indicator.secondaryTargetValueNumeric - indicator.secondaryStartValueNumeric).toLocaleString()} />
      </div>
    }
    { indicator.targetValueNumeric !== 1 && indicator.tertiaryTargetValueNumeric > 0 && indicator.tertiaryStartValueNumeric >= 0 &&
      <div className={classes.contentWrapper}>
      <Typography color="textSecondary">
        {indicator.tertiaryTargetValueDescription}
      </Typography>
      <StyledLinearProgress
      value={Math.round((arrSumTertiary(indicator.indicatorValues.filter(checkOrganisationFilter))) / (indicator.tertiaryTargetValueNumeric - indicator.tertiaryStartValueNumeric) * 100)}
      current={arrSumTertiary(indicator.indicatorValues.filter(checkOrganisationFilter)).toLocaleString()}
      target={(indicator.tertiaryTargetValueNumeric - indicator.tertiaryStartValueNumeric).toLocaleString()} />
      </div>
    }
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width={'25%'}>Description</TableCell>
            <TableCell width={'10%'}>Date</TableCell>
            <TableCell width={'25%'}>Comments</TableCell>
            <TableCell width={'25%'}>Organisation</TableCell>
            {indicator.targetValueNumeric !== 1 &&
              <TableCell width={'5%'}>Value</TableCell>
            }
            <TableCell width={'10%'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {indicator.indicatorValues.filter(checkOrganisationFilter).map((indicatorValue) => (
              <TableRow key={indicatorValue.id}>
                <TableCell width={'30%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.description}</TableCell>
                <TableCell width={'10%'}>{formatTime(indicatorValue.creationDate, 'dd.LL.yyyy')}</TableCell>
                <TableCell width={'25%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.comment}</TableCell>
                <TableCell width={'20%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.organisation ? <Chip label={indicatorValue.organisation.name} onClick={handleOrganisationClicked}/> : null}</TableCell>
                {indicator.targetValueNumeric !== 1 &&
                  <TableCell
                      width={'5%'}><strong>{indicatorValue.numericValue.toLocaleString()}</strong> {indicatorValue.secondaryNumericValue
                      ? `/ ${indicatorValue.secondaryNumericValue.toLocaleString()}`
                      : ''} {indicatorValue.tertiaryNumericValue
                      ? `/ ${indicatorValue.tertiaryNumericValue.toLocaleString()}`
                      : ''}</TableCell>
                }
                <TableCell width={'10%'}>
                  <div style={{display: 'flex'}} >
                    <AddIndicatorValueDialog action={'edit'} indicator={indicator} entity={indicatorValue} confirmationHandler={updateValueHandler} />
                    <ConfirmActionDialog action="delete" confirmationHandler={() => removeValueHandler(indicator.id, indicatorValue.id)}/>
                  </div>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div className={classes.contentWrapper}>
      <AddIndicatorValueDialog indicator={indicator} confirmationHandler={confirmationHandler}/>
    </div>
  </Paper>
}

function TabbedOutputIndicators(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [indicators, setIndicators] = useState([]);

  const cancelRequest = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const removeIndicatorValue = async (indicatorId, indicatorValueId) => {
    await deleteIndicatorValue(indicatorId, indicatorValueId);
    await fetchData();
  };

  const confirmationHandler =
      async (indicatorId, description, activity, comment, organisation, numericValue, secondaryNumericValue, tertiaryNumericValue) => {
        await addIndicatorValue(indicatorId, {
          'description': description,
          'activity': activity,
          'comment': comment,
          'organisation': organisation,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        });
        await fetchData();
      };

  const confirmIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, activity, comment, organisation,
          numericValue, secondaryNumericValue, tertiaryNumericValue) => {
        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, {
          'description': description,
          'activity': activity,
          'comment': comment,
          'organisation': organisation,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        });
        await fetchData();
      };


  const updateIndicatorValueDataAndDispatch = async (indicatorId, indicatorValueId, fields) => {
    try {
      await updateIndicatorValue(
          indicatorId,
          indicatorValueId,
          fields
      );
    } catch (error) {
    }
  };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIndicators();
          const indicatorData = result.data;

          if (!cancelRequest.current) {
            if (indicatorData && Object.keys(indicatorData).length > 0) {
              setIndicators(indicatorData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
  };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Unable to fetch indicators or no indicators were added in this category!
            </Typography>
          </div>
      );
    }

    if (isLoading || !indicators) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="secondary" />
          </div>
      );
    }

    return (
        <>
          <AppBar
              component="div"
              className={classes.appBar}
              color="primary"
              position="static"
              elevation={0}
          >
            <Tabs value={value} textColor={'#fff'} TabIndicatorProps={{style: {background:'white'}}} onChange={handleChange}>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 1" {...a11yProps(0)}/>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 2" {...a11yProps(1)}/>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 3" {...a11yProps(2)}/>
              <Tab style={{fontSize: 14, fontWeight: "normal"}} label="Output 4" {...a11yProps(3)}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {indicators.map((indicator) => {
              if ('OUTPUT_1' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {indicators.map((indicator) => {
              if ('OUTPUT_2' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {indicators.map((indicator) => {
              if ('OUTPUT_3' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {indicators.map((indicator) => {
              if ('OUTPUT_4' === indicator.indicatorType) {
                return <OutputIndicatorPaper key={indicator.id} classes={classes} indicator={indicator} confirmationHandler={confirmationHandler} removeValueHandler={removeIndicatorValue} updateValueHandler={confirmIndicatorValueUpdate}/>
              } else return null;
            })}
          </TabPanel>
        </>
    );
  };

  return renderData();
}

TabbedOutputIndicators.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabbedOutputIndicators);
