import React, {useEffect, useRef, useState} from 'react';
import {
  CircularProgress,
  Grid,
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Typography
} from "@mui/material";
import {withStyles} from "@mui/styles";
import * as PropTypes from "prop-types";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {getStatistics} from "../../utils/api";
import {formatTime} from "../../utils/timeHelper";
import Chart from "react-apexcharts";
import {formatActivityType} from "../../utils/activityTypeHelper";
import {truncate} from "../../utils/stringHelper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const styles = () => ({
  paper: {
    padding: 15,
    marginTop: 10
  },
});

MapContainer.propTypes = {
  style: PropTypes.shape(
      {paddingTop: PropTypes.string, height: PropTypes.number}),
  scrollWheelZoom: PropTypes.bool,
  zoom: PropTypes.number,
  center: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node
};

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
  main: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  const { value, current, target, classes } = props;

  return (
      <Box style={{ marginTop: '0.3rem', marginBottom: '0.3rem' }} display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress variant="determinate" value={value > 100 ? 100 : value} classes={classes} />
        </Box>
        <Box minWidth={100}>
          <Typography variant="body2" color="textSecondary">
            {current} / {target}
          </Typography>
        </Box>
      </Box>
  );
}

function OverallDashboard(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [statistics, setStatistics] = useState('');

  const cancelRequest = useRef();

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getStatistics();
          const statisticsData = result.data;

          if (!cancelRequest.current) {
            if (statisticsData && Object.keys(statisticsData).length > 0) {
              setStatistics(statisticsData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const position = [7.728172519030114, 110.65315778584099];

  const pieChartDataSeries = [];
  const pieChartDataOptions = {
    labels: [],
    legend: {
      show: true,
      position: 'bottom',
    },
    colors: [
      '#003f5c',
      '#2f4b7c',
      '#665191',
      '#a05195',
      '#d45087',
      '#f95d6a',
      '#ff7c43',
      '#ffa600'
    ],
  };

  if (statistics.activityTypes) {
    statistics.activityTypes.map((type) => {
      pieChartDataSeries.push(type.amount);
      pieChartDataOptions.labels.push(formatActivityType(type.activityType));
    })
  }

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !statistics) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="secondary" />
            </div>
          </>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }}  variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Map
            </Typography>
            <MapContainer center={position} zoom={4} scrollWheelZoom={false} style={{height: 450, paddingTop: '2rem'}}>
              <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              { statistics.locationStatistics && statistics.locationStatistics.map((location) => (
                  <>
                    { location.lat && location.lng &&
                    <Marker
                        position={[location.lat, location.lng]} key={location.id}>
                      <Popup>
                        <div style={{fontSize: 14, fontWeight: 'bold'}}>{location.englishName}</div>
                        {location.pastActivities && location.pastActivities.length > 0 &&
                        <strong>Past activities:</strong>
                        }
                        <ul>
                          {location.pastActivities && location.pastActivities.map((a) => (
                              <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)})`}</a></li>
                          ))}
                        </ul>
                        {location.ongoingActivities && location.ongoingActivities.length > 0 &&
                        <strong>Ongoing:</strong>
                        }
                        <ul>
                          {location.ongoingActivities && location.ongoingActivities.map((a) => (
                              <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)})`}</a></li>
                          ))}
                        </ul>
                        {location.activitiesPlanned && location.activitiesPlanned.length > 0 &&
                        <strong>Upcoming:</strong>
                        }
                        <ul>
                          {location.activitiesPlanned && location.activitiesPlanned.map((a) => (
                              <li><a href={'/activity/' + a.id}>{`${a.name} (${formatTime(a.startDate)})`}</a></li>
                          ))}
                        </ul>
                      </Popup>
                    </Marker>
                    }
                  </>
              ))}
            </MapContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{height: '100%'}} variant="outlined"
                 className={classes.paper}>
            <Typography variant="h5" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              Types of activities
            </Typography>
            <Chart options={pieChartDataOptions} series={pieChartDataSeries} type="pie" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{height: '100%'}} variant="outlined"
                 className={classes.paper}>
            <Typography variant="h5" color="textPrimary"
                        style={{paddingBottom: '1rem'}}>
              Recently conducted activities
            </Typography>
            {statistics.pastActivities.length > 0 &&
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width={'40%'}>Name</TableCell>
                    <TableCell width={'30%'}>Type</TableCell>
                    <TableCell width={'30%'}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics.pastActivities
                  && statistics.pastActivities.map(
                      (activity) => (
                          <TableRow key={activity.id}>
                            <TableCell component="th"
                                       scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                            <TableCell>{formatActivityType(
                                activity.activityType)}</TableCell>
                            <TableCell>{formatTime(
                                activity.startDate)}</TableCell>
                          </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Upcoming activities
            </Typography>
            {statistics.upcomingActivities.length > 0 &&
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width={'40%'}>Name</TableCell>
                    <TableCell width={'30%'}>Type</TableCell>
                    <TableCell width={'30%'}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics.upcomingActivities
                  && statistics.upcomingActivities.map(
                      (activity) => (
                          <TableRow key={activity.id}>
                            <TableCell component="th"
                                       scope="row"><a href={'/activity/' + activity.id}>{activity.name}</a></TableCell>
                            <TableCell>{formatActivityType(
                                activity.activityType)}</TableCell>
                            <TableCell>{formatTime(
                                activity.startDate)}</TableCell>
                          </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Specific Objectives
            </Typography>
            { statistics.specificProgressDetails && statistics.specificProgressDetails.map((outcome) => (
                <div className={classes.contentWrapper} key={outcome.indicatorShortName}>
                  <Typography variant="body1" color="textPrimary">{outcome.indicatorName}</Typography>
                  <Typography variant="body2">{truncate(outcome.description, 140)}</Typography>
                  <StyledLinearProgress
                      value={outcome.progress * 100}
                      current={outcome.currentValue}
                      target={outcome.targetValue}/>
                  { outcome.indicatorLevels && outcome.indicatorLevels > 1 &&
                      <StyledLinearProgress
                          value={outcome.secondaryProgress * 100}
                          current={outcome.secondaryCurrentValue}
                          target={outcome.secondaryTargetValue}/>
                  }
                  { outcome.indicatorLevels && outcome.indicatorLevels > 2 &&
                      <StyledLinearProgress
                          value={outcome.tertiaryProgress * 100}
                          current={outcome.tertiaryCurrentValue}
                          target={outcome.tertiaryTargetValue}/>
                  }
                </div>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ height: '100%' }} variant="outlined" className={classes.paper}>
            <Typography variant="h5" color="textPrimary" style={{paddingBottom: '1rem'}}>
              Output Indicators
            </Typography>
            { statistics.outputProgressDetails && statistics.outputProgressDetails.map((output) => (
                <div className={classes.contentWrapper} key={output.indicatorShortName}>
                  <Typography variant="body1" color="textPrimary">{output.indicatorName}</Typography>
                  <Typography variant="body2">{truncate(output.description, 140)}</Typography>
                  <StyledLinearProgress
                      value={output.progress * 100}
                      current={output.currentValue}
                      target={output.targetValue}/>
                  { output.indicatorLevels && output.indicatorLevels > 1 &&
                      <StyledLinearProgress
                          value={output.secondaryProgress * 100}
                          current={output.secondaryCurrentValue}
                          target={output.secondaryTargetValue}/>
                  }
                  { output.indicatorLevels && output.indicatorLevels > 2 &&
                      <StyledLinearProgress
                          value={output.tertiaryProgress * 100}
                          current={output.tertiaryCurrentValue}
                          target={output.tertiaryTargetValue}/>
                  }
                </div>
            ))}
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return renderData();
}

export default withStyles(styles)(OverallDashboard);