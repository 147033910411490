import React from 'react';

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AutoComplete from "@mui/material/Autocomplete";

export default function BasicAutocomplete ({handleChange, searchFct, currentValue, label, property, scndProperty, project, groupBy = null, forceIndicatorSort = false}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const onInputValueChange = async (event) => {
    if (event) {
      await searchFct(event.target.value)
        .then((response) => setOptions(response.data));
    }

  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await searchFct('');

      if (active) {
        if (response.data) {
          setOptions(response.data);
        } else {
          setOptions([]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
      <AutoComplete
          style={{ marginTop: 20 }}
          id="autocomplete"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          groupBy={groupBy}
          onChange={(e, selectedValue) => {
            handleChange(selectedValue);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => scndProperty !== undefined ?
                  option[property] + ' (' + option[scndProperty] + ')' :
                  `${option[property]}`}
          onInputChange={onInputValueChange}
          value={currentValue === '' ? null : currentValue}
          options={project || forceIndicatorSort ? options.filter(o => o.indicatorType === 'CLUSTER' || project ? (o.projects[0] && o.projects[0].id === project.id) : true).sort(function(a, b) {
            if (a.indicatorType > b.indicatorType) {
              return 1;
            } else if (a.indicatorType < b.indicatorType) {
              return -1;
            }
            let name1 = a.name.toUpperCase();
            let name2 = b.name.toUpperCase();
            return (name1 < name2) ? -1 : (name1 > name2) ? 1 : 0;
          }) : options}
          loading={loading}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                  }}
              />
          )}
      />
  );
}