import React from 'react';
import './App.css';

import Paperbase from './components/Paperbase'
import UserProvider from "./components/auth/UserProvider";

class App extends React.Component {
  render() {
    return (
        <div className="App">
          <UserProvider>
            <Paperbase />
          </UserProvider>
        </div>
    );
  }
}

export default App;