import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBuDSENBZy4YFE-JKfKS3pCirDhojBb3dM",
  authDomain: "a-sassy-meal.firebaseapp.com",
  projectId: "a-sassy-meal",
  storageBucket: "a-sassy-meal.appspot.com",
  messagingSenderId: "119670334445",
  appId: "1:119670334445:web:a7a445e3b16cdd41017de4"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();