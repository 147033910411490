import React from 'react';
import {Avatar, Chip} from "@mui/material";
import {withStyles} from "@mui/styles";


const styles = () => ({
  block: {
    display: 'block',
  },
});

function UserRoleChip(props) {
  const { role } = props;

  const formatRole = (rawType) => {
    if (!rawType) {
      return null;
    }

    switch (rawType) {
      case 'READER':
        return 'Read-Only User';
      case 'SUPER':
        return 'Super Admin';
      case 'STANDARD':
        return 'Standard User';
      default:
        return '';
    }
  }

  const formatShortRole = (rawType) => {
    if (!rawType) {
      return null;
    }

    switch (rawType) {
      case 'READER':
        return 'RO';
      case 'SUPER':
        return 'SA';
      case 'STANDARD':
        return 'STD';
      case 'APP':
        return 'APP';
      default:
        return '';
    }
  }

  let roleString = formatRole(role);

  return (
      <Chip sx={{ fontSize: 14 }} avatar={<Avatar>{formatShortRole(role)}</Avatar>} label={roleString} />
  );
}

export default withStyles(styles)(UserRoleChip)