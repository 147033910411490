import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  addRoleToUser,
  createUser, deleteUser, getUsers, updateOrganisationForUser
} from "../../utils/api";
import DataGridActionBar from "../parts/DataGridActionBar";
import {formatTime} from "../../utils/timeHelper";
import AddUserDialog from "../dialogs/AddUserDialog";
import ConfirmResetUserDialog from "../dialogs/ConfirmResetUserDialog";
import UserRoleChip from "../parts/UserRoleChip";
import {CircularProgress} from "@mui/material";
import {withStyles} from "@mui/styles";
import {DataGrid} from "@mui/x-data-grid";
import { auth } from "../auth/firebase";
import {UserContext} from "../auth/UserProvider";
import ChangeUserDialog from "../dialogs/ChangeUserDialog";
import ChangeUserRoleDialog from "../dialogs/ChangeUserRoleDialog";

const styles = () => ({
  dataGrid: {
    backgroundColor: 'white'
  }
});


function UserSettings(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [userList, setUserList] = useState('');

  const authState = useContext(UserContext);

  const addConfirmationHandler =
      async (name, email, role, organisation) => {
        await createUser({
          'name': name,
          'email': email,
          'role': role,
          'organisation': organisation
        }).then(() => {
          auth.sendPasswordResetEmail(email);
        });
        await fetchData();
      };

  const resetUserPassword =
      async (email) => {
        await auth.sendPasswordResetEmail(email).then(() => {
        });
        await fetchData();
      };

  const updateConfirmationHandler =
      async (id, uid, organisation) => {
        await updateOrganisationForUser(id,{
          'uid': uid,
          'organisationId': organisation && organisation.id > 0 ? organisation.id : 1
        })
        await fetchData();
      };

  const updateRoleConfirmationHandler =
      async (uid, role) => {
        await addRoleToUser({
          'uid': uid,
          'role': role
        })
        await fetchData();
      };

  const cancelRequest = useRef();

  const fetchData =
      async (loading, page = 0, size = 50) => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getUsers(page - 1, size);
          const rolesData = result.data;

          if (!cancelRequest.current) {
            if (rolesData && Object.keys(rolesData).length > 0) {
              setUserList(rolesData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsForbidden(true);
          } else if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false, 0);
  }, []);

  const removeUser = async (userId) => {
    await deleteUser(userId);
    await fetchData();
  };

  const renderData = () => {
    if (isError) {
      return (
          <>
            Error
          </>
      );
    }

    if (isForbidden) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              Access denied!
            </div>
          </>
      );
    }

    if (isLoading || !userList) {
      return (
          <>
            <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
              <CircularProgress style={{marginTop: '10%'}} color="secondary" />
            </div>
          </>
      );
    }

    return (
        <>
          <DataGrid
              hideFooter={true}
              sortingOrder={['asc', 'desc', null]}
              scrollbarSize={15}
              columnTypes={['string', 'number', 'date', 'dateTime']}
              columnBuffer={2}
              headerHeight={56}
              localeText={'enUS'}
              rowHeight={52}
              icons={[]}
              className={classes.dataGrid}
              rows={userList}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                { field: 'role', headerName: 'Role', flex: 1,
                  renderCell: (params) =>
                      <UserRoleChip role={params.row.role}/>
                },
                { field: 'organisation', headerName: 'Organisation', flex: 1,
                  renderCell: (params) =>
                      params.row.organisation ? params.row.organisation.name : ''
                },
                {
                  field: "dateRegistered",
                  headerName: "Creation date",
                  flex: 1,
                  renderCell: (params) =>
                      (formatTime(params.row.dateCreated, 'dd.LL.yyyy HH:mm'))
                },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  disableClickEventBubbling: true,
                  sortable: false,
                  disableColumnMenu: true,
                  flex: 1,
                  renderCell: (params) => {
                    return <>
                        <ChangeUserDialog user={params.row} confirmationHandler={updateConfirmationHandler}/>
                        <ChangeUserRoleDialog user={params.row} confirmationHandler={updateRoleConfirmationHandler}/>
                        <ConfirmResetUserDialog userEmail={params.row.email} confirmationHandler={resetUserPassword}/>
                        <DataGridActionBar
                          data={params.row}
                          type={'User'}
                          hideEdit={true}
                          confirmationHandler={(userId) => removeUser(userId)}>
                        </DataGridActionBar>
                      </>;
                  }
                }
              ]}
              density="compact"
              rowCount={userList.length}
          />
          {authState.role !== 'ROLE_READER' &&
            <AddUserDialog
                confirmationHandler={addConfirmationHandler}/>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(UserSettings);