import React from 'react';
import {withStyles} from "@mui/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Description,
  DescriptionList,
  DescriptionTerm
} from "../parts/Description.parts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ChangeRoleIcon from "@mui/icons-material/ManageAccounts";

const styles = () => ({
  paper: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

function ChangeUserRoleDialog(props) {
  const { classes, confirmationHandler, user } = props;
  const [role, setRole] = React.useState('ROLE_STANDARD');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRole('0');
  };

  const handleConfirm = () => {
    confirmationHandler(user.uid, role);
    handleClose();
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  return (
      <div>
        <Tooltip title="Change role for user">
          <IconButton onClick={handleClickOpen}>
            <ChangeRoleIcon className={classes.block} color="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth={true} maxWidth={'xs'} className={classes.dialog} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change role for {user.email}</DialogTitle>
          <DialogContent>
            <DescriptionList>
              <DescriptionTerm>Role</DescriptionTerm>
              <Description>
                <Select
                    labelId="organisation-select"
                    id="organisation-select"
                    value={role}
                    variant="outlined"
                    className={classes.selectEmpty}
                    onChange={handleRoleChange}
                >
                  <MenuItem value={'ROLE_SUPER'}>Super Admin</MenuItem>
                  <MenuItem value={'ROLE_STANDARD'}>Standard</MenuItem>
                  <MenuItem value={'ROLE_READER'}>Read-only</MenuItem>
                </Select>
              </Description>
            </DescriptionList>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default withStyles(styles)(ChangeUserRoleDialog)