import React from 'react';
import PropTypes from 'prop-types';
import appTheme from "../config/appTheme";
import {StylesProvider, ThemeProvider} from "@mui/styles";
import {CssBaseline} from "@mui/material";
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

function GlobalTheme({ children }) {
  return (
      <StylesProvider injectFirst>
        <StyledThemeProvider theme={appTheme}>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledThemeProvider>
      </StylesProvider>
  );
}

GlobalTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default GlobalTheme;