import axios from 'axios';
import { auth } from "../components/auth/firebase";

let token = null;

async function setupAuthorizationHeaders() {
  token = await auth.currentUser.getIdToken();

  return {
    Authorization: `Bearer ${token}`,
  };
}

const api = axios.create({
  baseURL: process.env.REACT_APP_ASASSY_MEAL_BACKEND,
  headers: {
    accept: 'application/json',
  },
});

api.interceptors.request.use(
    async config => {
      const newConfig = config;
      const newHeaders = await setupAuthorizationHeaders();
      newConfig.headers = {
        ...config.headers,
        ...newHeaders,
      };
      return newConfig;
    },
    error => {
      Promise.reject(error);
    }
);

export default api;

/* --- GET requests --- */

export async function getActivities(state, searchTerm = '', page = 0, size = 10) {
  return api.get(`/activities?state=${state}&searchTerm=${searchTerm}&page=${page}&size=${size}`);
}

export async function getActivity(activityId) {
  return api.get(`/activities/${activityId}`);
}

export async function getIndicators() {
  return api.get('/indicators');
}

export async function getBudgets() {
  return api.get('/budgets');
}

export async function getOutputActivities() {
  return api.get('/output-activities');
}

export async function getStatistics() {
  return api.get('/statistics');
}

export async function getOrganisationStatistics(organisationId) {
  return api.get(`/organisation-statistics/${organisationId}`);
}

export function getProgrammeAnalysis() {
  return api.get('/analysis/programme');
}

export function getModuleAnalysis() {
  return api.get('/analysis/module');
}

export function getOutputAnalysis(outputId) {
  return api.get(`/analysis/output/${outputId}`);
}

export async function getUsers() {
  return api.get(`/users`);
}

export async function getLocations() {
  return api.get(`/locations`);
}

export async function getOrganisations() {
  return api.get(`/organisations`);
}

export async function searchOrganisations(searchTerm = '') {
  return api.get(`/organisations-search?searchTerm=${searchTerm}`);
}

export async function searchActivities(searchTerm) {
  return api.get(`/activities-search?searchTerm=${searchTerm}`);
}

export async function searchLocations(searchTerm = '') {
  return api.get(`/locations-search?searchTerm=${searchTerm}`);
}

export async function searchOutputActivities(searchTerm) {
  return api.get(`/output-activities-search?searchTerm=${searchTerm}`);
}

export async function searchOutputIndicators(searchTerm) {
  return api.get(`/output-indicators-search?searchTerm=${searchTerm}`);
}

export async function getActivityDump() {
  return api.get('/activities-dump');
}

/* --- POST requests ---*/

export async function createActivity(body) {
  return api.post(`/activities`, body);
}

export async function createIndicator(body) {
  return api.post(`/indicators`, body);
}

export async function addIndicatorValue(indicatorId, body) {
  return api.post(`/indicators/${indicatorId}/value`, body);
}

export async function addOutputActivityValue(outputActivityId, body) {
  return api.post(`/output-activities/${outputActivityId}/value`, body);
}

export async function addBudgetValue(budgetId, body) {
  return api.post(`/budgets/${budgetId}/value`, body);
}

export async function addTargetValue(activityId, body) {
  return api.post(`/activities/${activityId}/targetvalue`, body);
}

export async function addSurveyEntry(activityId, body) {
  return api.post(`/activities/${activityId}/survey/entry`, body);
}

export async function addFollowUpSurveyEntry(activityId, body) {
  return api.post(`/activities/${activityId}/followupsurvey/entry`, body);
}

export async function createUser(body) {
  return api.post(`/users`, body);
}

export async function createLocation(body) {
  return api.post(`/locations`, body);
}

export async function addRoleToUser(body) {
  return api.post(`/roles`, body);
}

/* --- PUT requests ---*/

export async function updateActivity(activityId, body) {
  return api.put(`/activities/${activityId}`, body);
}

export async function updateIndicator(indicatorId, body) {
  return api.put(`/indicators/${indicatorId}`, body);
}

export async function updateIndicatorValue(indicatorId, indicatorValueId, body) {
  return api.put(`/indicators/${indicatorId}/value/${indicatorValueId}`, body);
}

export async function updateOutputActivityValue(outputActivityId, indicatorValueId, body) {
  return api.put(`/output-activities/${outputActivityId}/value/${indicatorValueId}`, body);
}
export async function updateLocation(locationId, body) {
  return api.put(`/locations/${locationId}`, body);
}

export async function updateOrganisationForUser(id, body) {
  return api.put(`/user-organisations/${id}`, body);
}

/* --- DELETE requests ---*/

export async function deleteActivity(activityId, deleteType = 'SOFT') {
  return api.delete(`/activities/${activityId}?deleteType=${deleteType}`);
}

export async function deleteIndicator(indicatorId) {
  return api.delete(`/indicators/${indicatorId}`);
}

export async function deleteEntryFromSurvey(activityId, entryId) {
  return api.delete(`/activities/${activityId}/survey/entry/${entryId}`);
}

export async function deleteEntryFromFollowUpSurvey(activityId, entryId) {
  return api.delete(`/activities/${activityId}/followupsurvey/entry/${entryId}`);
}

export async function deleteTargetValue(activityId, targetValueId) {
  return api.delete(`/activities/${activityId}/targetvalue/${targetValueId}`);
}

export async function deleteIndicatorValue(indicatorId, indicatorValueId) {
  return api.delete(`/indicators/${indicatorId}/value/${indicatorValueId}`);
}

export async function deleteBudgetValue(budgetId, budgetValueId) {
  return api.delete(`/budgets/${budgetId}/value/${budgetValueId}`);
}

export async function deleteUser(usersId) {
  return api.delete(`/users/${usersId}`);
}