import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Checkbox, FormControlLabel, FormGroup, MenuItem, Select, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {Description, DescriptionList, DescriptionTerm} from "../parts/Description.parts";
import {getOrganisations} from "../../utils/api";
import {UserContext} from "../auth/UserProvider";

export default function AddOutputActivityValueDialog(props) {
  const { indicator, entity, confirmationHandler, action = 'create' } = props;
  const [open, setOpen] = useState(false);
  const [indicatorValueDescription, setIndicatorValueDescription] = useState('');
  const [indicatorValueActivity, setIndicatorValueActivity] = useState(undefined);
  const [indicatorValueComment, setIndicatorValueComment] = useState('');
  const [numericValue, setNumericValue] = useState(0);
  const [secondaryNumericValue, setSecondaryNumericValue] = useState(0);
  const [tertiaryNumericValue, setTertiaryNumericValue] = useState(0);
  const [organisation, setOrganisation] = useState(undefined);
  const [availableOrganisations, setAvailableOrganisations] = React.useState([]);

  const authState = useContext(UserContext);

  const cancelRequest = useRef();

  const handleOrganisationChange = (event) => {
    setOrganisation(availableOrganisations.find(p => p.id === event.target.value));
  };

  const fetchData =
      async () => {
        try {
          const result = await getOrganisations();
          const orgData = result.data;

          if (!cancelRequest.current) {
            if (orgData && Object.keys(orgData).length > 0) {
              setOrganisation(orgData[0]);
              setAvailableOrganisations(orgData);
            }
          }
        } catch (error) {
        }
      };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setIndicatorValueDescription(entity && entity.description ? entity.description : '');
    setIndicatorValueActivity(entity && entity.activity ? entity.activity : undefined);
    setIndicatorValueComment(entity && entity.comment ? entity.comment : '');
    setOrganisation(entity && entity.organisation ? entity.organisation : undefined);
    setNumericValue(entity && entity.numericValue ? entity.numericValue : 0);
    setSecondaryNumericValue(entity && entity.secondaryNumericValue ? entity.secondaryNumericValue : 0);
    setTertiaryNumericValue(entity && entity.tertiaryNumericValue ? entity.tertiaryNumericValue : 0);
  };

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleConfirm = () => {
    action === 'create' ?
      confirmationHandler(
          indicator.id,
          indicatorValueDescription,
          indicatorValueActivity,
          indicatorValueComment,
          numericValue,
          indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
          indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null,
          organisation) :
        confirmationHandler(
            entity.id,
            indicator.id,
            indicatorValueDescription,
            indicatorValueActivity,
            indicatorValueComment,
            numericValue,
            indicator && indicator.secondaryTargetValueNumeric ? secondaryNumericValue : null,
            indicator && indicator.tertiaryTargetValueNumeric ? tertiaryNumericValue : null,
            organisation);
    handleClose();
  };

  const clearFormData = () => {
    setIndicatorValueDescription('');
    setIndicatorValueActivity(undefined);
    setIndicatorValueComment('');
    setOrganisation(undefined);
    setNumericValue(0);
    setSecondaryNumericValue(0);
    setTertiaryNumericValue(0);
  };

  const onIndicatorValueDescriptionChange = (e) => {
    setIndicatorValueDescription(e.target.value);
  };

  const onIndicatorValueCommentChange = (e) => {
    setIndicatorValueComment(e.target.value);
  };

  const onNumericValueChange = (e) => {
    setNumericValue(e.target.value);
  };

  const onSecondaryNumericValueChange = (e) => {
    setSecondaryNumericValue(e.target.value);
  };

  const onTertiaryNumericValueChange = (e) => {
    setTertiaryNumericValue(e.target.value);
  };

  return (
      <div>
        {action === 'create' &&
          <Button variant="outlined" color="secondary" sx={{fontSize: 14}} onClick={handleClickOpen}>
            Add { indicator && indicator.targetValueNumeric === 1 ? 'milestone' : 'entry'}
          </Button>
        }
        { action === 'edit' &&
        <Tooltip title="Edit">
          <IconButton aria-label="edit"
                      onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        }
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{ action === 'create' ? 'Add' : 'Edit'} { indicator && indicator.targetValueNumeric === 1 ? 'milestone' : 'entry'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please add the necessary information
            </DialogContentText>
            <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Description"
                type="text"
                value={indicatorValueDescription}
                onChange={onIndicatorValueDescriptionChange}
                fullWidth
                variant="outlined"
            />
            <TextField
                autoFocus={false}
                margin="normal"
                id="name"
                label="Comment"
                type="text"
                value={indicatorValueComment}
                onChange={onIndicatorValueCommentChange}
                fullWidth
                variant="outlined"
            />
            <DescriptionList>
              <DescriptionTerm>Organisation</DescriptionTerm>
              <Description>
                <Select
                    labelId="org-select"
                    id="org-select"
                    value={organisation ? organisation.id + '' : authState.org ? authState.org.split('_')[1] : '1'}
                    variant="outlined"
                    onChange={handleOrganisationChange}
                    disabled={authState.org}
                >
                  {availableOrganisations.length > 0 && availableOrganisations.filter(avOrg => avOrg.id === parseInt(authState.org.split('_')[1])).map((p) => (
                      <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                  ))}
                </Select>
              </Description>
            </DescriptionList>
            <br/>
            { indicator && indicator.targetValueNumeric === 1 &&
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={numericValue !== 0} onChange={(event) => setNumericValue(event.target.checked ? 1 : 0)} />} label="Mark milestone as complete" />
              </FormGroup>
            }
            { indicator && indicator.targetValueNumeric !== 1 &&
              <TextField
                  autoFocus={false}
                  margin="normal"
                  id="name"
                  label="Value"
                  value={numericValue}
                  onChange={onNumericValueChange}
                  type="number"
                  variant="outlined"
              />
            }
            { indicator && indicator.secondaryTargetValueNumeric &&
                <>
                  <br/>
                  <TextField
                      autoFocus={false}
                      margin="normal"
                      id="name"
                      label={`Secondary value (${indicator.secondaryTargetValueDescription})`}
                      value={secondaryNumericValue}
                      onChange={onSecondaryNumericValueChange}
                      type="number"
                      variant="outlined"
                  />
                </>
            }
            { indicator && indicator.tertiaryTargetValueNumeric &&
              <>
                <br/>
                <TextField
                    autoFocus={false}
                    margin="normal"
                    id="name"
                    label={`Tertiary value (${indicator.tertiaryTargetValueDescription})`}
                    value={tertiaryNumericValue}
                    onChange={onTertiaryNumericValueChange}
                    type="number"
                    variant="outlined"
                />
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" size="small">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" size="small">
              { action === 'create' ? 'Add' : 'Edit'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}