import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import {
  addBudgetValue, deleteBudgetValue,
  getBudgets
} from "../../utils/api";
import {
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {formatCurrencyValue} from "../../utils/stringHelper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBudgetValueDialog from "../dialogs/AddBudgetValueDialog";
import {getQuarter} from "../../utils/timeHelper";
import styled from "styled-components";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const arrSumBudgetLine = arr => arr.filter(v => v.budgetLine !== undefined).reduce((a, b) => a + b.budgetLine, 0);
const arrSumNumericValues = arr => arr.filter(v => v.numericValue !== undefined).reduce((a, b) => a + b.numericValue, 0);

function LinearProgressWithLabel(props) {
  const { value, current, target, classes, isEu = false } = props;

  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 24}} color={ isEu ? 'secondary' : 'primary'} variant="determinate" value={value > 100 ? 100 : value} classes={classes} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary"><strong>{`${value}%`}</strong></Typography>
          <Typography variant="body2" color="textSecondary">
            {current} / {target}
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function BudgetPaper(props) {
  const { budget, disableAddingValues = false, budgetValueConfirmationHandler, removeValueConfirmationHandler, selectedYear } = props;

  const [open, setOpen] = useState(false);

  return <>
    <TableRow key={budget.id}>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell>{budget.abbreviation + ' ' + budget.name}</TableCell>
      <TableCell style={{textAlign: 'right'}}>{formatCurrencyValue(budget.accumulatedBudgetValuesOverYears)} €</TableCell>
      <TableCell style={{textAlign: 'right'}}>{formatCurrencyValue(budget.budgetLine - budget.accumulatedBudgetValuesOverYears)} €</TableCell>
      <TableCell style={{textAlign: 'right'}}>{formatCurrencyValue(budget.budgetLine)} €</TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
        <Collapse style={{marginTop: '1rem', marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
          <TableContainer sx={{borderRadius: 2, border: '1px solid #002C76'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Quarter</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'right'}}/>
                  <StyledTableCell style={{textAlign: 'right'}}>Amount</StyledTableCell>
                  <StyledTableCell style={{textAlign: 'right'}}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} sx={{bgcolor: '#eff5ff'}}><strong>1st quarter</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}><strong>BfdW</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'BfdW').filter(bValue => getQuarter(bValue.attributionDate) === 1).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>API</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'API').filter(bValue => getQuarter(bValue.attributionDate) === 1).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>ReBoot</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'ReBoot').filter(bValue => getQuarter(bValue.attributionDate) === 1).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>AFV</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'AFV').filter(bValue => getQuarter(bValue.attributionDate) === 1).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>YFI</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'YFI').filter(bValue => getQuarter(bValue.attributionDate) === 1).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell width={'30%'} />
                  <TableCell width={'30%'} style={{textAlign: 'right'}}><strong>Accumulated  Quarterly  Expenses</strong></TableCell>
                  <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(arrSumNumericValues(budget.budgetValues.filter(bValue => getQuarter(bValue.attributionDate) === 1)))} €</TableCell>
                  <TableCell width={'5%'} style={{textAlign: 'right'}}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{bgcolor: '#eff5ff'}}><strong>2nd quarter</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}><strong>BfdW</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'BfdW').filter(bValue => getQuarter(bValue.attributionDate) === 2).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>API</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'API').filter(bValue => getQuarter(bValue.attributionDate) === 2).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>ReBoot</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'ReBoot').filter(bValue => getQuarter(bValue.attributionDate) === 2).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>AFV</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'AFV').filter(bValue => getQuarter(bValue.attributionDate) === 2).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>YFI</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'YFI').filter(bValue => getQuarter(bValue.attributionDate) === 2).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell width={'30%'} />
                  <TableCell width={'30%'} style={{textAlign: 'right'}}><strong>Accumulated  Quarterly  Expenses</strong></TableCell>
                  <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(arrSumNumericValues(budget.budgetValues.filter(bValue => getQuarter(bValue.attributionDate) === 2)))} €</TableCell>
                  <TableCell width={'5%'} style={{textAlign: 'right'}}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6} sx={{bgcolor: '#eff5ff'}}><strong>3rd quarter</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}><strong>BfdW</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'BfdW').filter(bValue => getQuarter(bValue.attributionDate) === 3).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>API</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'API').filter(bValue => getQuarter(bValue.attributionDate) === 3).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>ReBoot</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'ReBoot').filter(bValue => getQuarter(bValue.attributionDate) === 3).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>AFV</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'AFV').filter(bValue => getQuarter(bValue.attributionDate) === 3).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>YFI</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'YFI').filter(bValue => getQuarter(bValue.attributionDate) === 3).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell width={'30%'} />
                  <TableCell width={'30%'} style={{textAlign: 'right'}}><strong>Accumulated  Quarterly  Expenses</strong></TableCell>
                  <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(arrSumNumericValues(budget.budgetValues.filter(bValue => getQuarter(bValue.attributionDate) === 3)))} €</TableCell>
                  <TableCell width={'5%'} style={{textAlign: 'right'}}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} sx={{bgcolor: '#eff5ff'}}><strong>4th quarter</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}><strong>BfdW</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'BfdW').filter(bValue => getQuarter(bValue.attributionDate) === 4).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>API</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'API').filter(bValue => getQuarter(bValue.attributionDate) === 4).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>ReBoot</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'ReBoot').filter(bValue => getQuarter(bValue.attributionDate) === 4).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>AFV</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'AFV').filter(bValue => getQuarter(bValue.attributionDate) === 4).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}><strong>YFI</strong></TableCell>
                </TableRow>
                { budget.budgetValues && budget.budgetValues.length > 0 && budget.budgetValues.filter(bValue => bValue.organisation && bValue.organisation.name === 'YFI').filter(bValue => getQuarter(bValue.attributionDate) === 4).map((bValue) => (
                    <TableRow key={bValue.id}>
                      <TableCell width={'15%'}>{bValue.description}</TableCell>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}/>
                      <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(bValue.numericValue)} €</TableCell>
                      <TableCell width={'5%'} style={{textAlign: 'right'}}>
                        <ConfirmActionDialog action="delete"
                                             confirmationHandler={() => removeValueConfirmationHandler(budget.id, bValue.id)}/>
                      </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell width={'30%'} />
                  <TableCell width={'30%'} style={{textAlign: 'right'}}><strong>Accumulated  Quarterly  Expenses</strong></TableCell>
                  <TableCell width={'15%'} style={{textAlign: 'right'}}>{formatCurrencyValue(arrSumNumericValues(budget.budgetValues.filter(bValue => getQuarter(bValue.attributionDate) === 4)))} €</TableCell>
                  <TableCell width={'5%'} style={{textAlign: 'right'}}></TableCell>
                </TableRow>
                  </TableBody>
              </Table>
          </TableContainer>
          { budget.budgetValues && budget.budgetValues.length < 1 &&
              <Typography variant="h6" color="textSecondary"></Typography>
          }
          <br/>
          <div width={'100%'} hidden={disableAddingValues}>
            <AddBudgetValueDialog selectedYear={selectedYear} budget={budget} confirmationHandler={budgetValueConfirmationHandler}  />
          </div>
        </Collapse>
      </TableCell>
    </TableRow>

  </>



}

function BudgetsPaper(props) {
  const { classes, budgets, disableAddingValues = false, budgetValueConfirmationHandler, removeValueConfirmationHandler, selectedYear } = props;

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h2" gutterBottom component="div">
          {budgets[0].description}
        </Typography>
      </Toolbar>
    </AppBar>
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell ><strong>Category</strong></TableCell>
            <TableCell style={{textAlign: 'right'}}><strong>Accumulated expenses</strong></TableCell>
            <TableCell style={{textAlign: 'right'}}><strong>Available budget</strong></TableCell>
            <TableCell style={{textAlign: 'right'}}><strong>Budget line</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { budgets.map((budget, idx) => (
              <BudgetPaper key={idx} selectedYear={selectedYear} budget={budget} disableAddingValues={disableAddingValues}
                           budgetValueConfirmationHandler={budgetValueConfirmationHandler} removeValueConfirmationHandler={removeValueConfirmationHandler}  />
          ))}
          <TableRow>
            <TableCell width={'10%'}><strong>Total budget</strong></TableCell>
            <TableCell width={'60%'} />
            <TableCell/>
            <TableCell width={'15%'} style={{textAlign: 'right'}}></TableCell>
            <TableCell width={'15%'} style={{textAlign: 'right'}}><strong>{formatCurrencyValue(arrSumBudgetLine(budgets))} €</strong></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
}

function Budgets(props) {
  const { classes } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [budgets, setBudgets] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());

  const cancelRequest = useRef();

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const budgetValueConfirmationHandler =
      async (budgetId, attributionDate, description, numericValue, organisation) => {
        await addBudgetValue(budgetId, {
          'attributionDate': attributionDate,
          'description': description,
          'numericValue': +numericValue,
          'organisation': organisation
        });
        await fetchData()
      };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getBudgets();
          const budgetData = result.data;

          if (!cancelRequest.current) {
            if (budgetData && Object.keys(budgetData).length > 0) {
              setBudgets(budgetData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const removeBudgetValue = async (budgetId, budgetValueId) => {
    await deleteBudgetValue(budgetId, budgetValueId);
    await fetchData();
  };

  let groupedBudgets = {};

  if (budgets) {
    groupedBudgets = budgets.filter(b => b.year === year).reduce(function (r, a) {
      r[a.groupId] = r[a.groupId] || [];
      r[a.groupId].push(a);
      return r;
    }, Object.create(null));
  }

  let groupedBudgetsArray = [];

  for (const key in groupedBudgets) {
    groupedBudgetsArray.push(groupedBudgets[key]);
  }

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Unable to fetch budgets!
            </Typography>
          </div>
      );
    }

    if (isLoading || (!budgets && !groupedBudgets)) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="secondary" />
          </div>
      );
    }

    return (
        <>
          <Paper className={classes.paper}>
            <AppBar className={classes.toolbar}
                    position="static"
                    color="default"
                    elevation={0}>
              <Toolbar>
                <Typography variant="h2" gutterBottom component="div">
                  Budget period
                </Typography>
              </Toolbar>
            </AppBar>
            <FormControl style={{ marginTop: '2rem' }} fullWidth>
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                  labelId="year-select-label"
                  id="year-simple-select"
                  value={year}
                  label="Year"
                  onChange={handleYearChange}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          { groupedBudgetsArray.map((budgets, idx) => {
            return <BudgetsPaper key={idx}
                                 classes={classes}
                                 budgets={budgets}
                                 selectedYear={year}
                                 budgetValueConfirmationHandler={budgetValueConfirmationHandler}
                                 removeValueConfirmationHandler={removeBudgetValue}
                                 disableAddingValues={year === 1000}/>
          })}
        </>
    );
  };

  return renderData();
}

Budgets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Budgets);
