import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import EditableInput from "../input/EditableInput";
import {
  createActivity, deleteActivity, deleteTargetValue,
  getActivities, getActivityDump, searchLocations, searchOrganisations,
  updateActivity
} from "../../utils/api";
import {formatTime} from '../../utils/timeHelper';
import {
  formatActivityType
} from "../../utils/activityTypeHelper";
import {truncate} from "../../utils/stringHelper";
import debounce from 'lodash.debounce';
import {useInView} from "react-cool-inview";
import {makeStyles, withStyles} from "@mui/styles";
import {
  Tooltip, Typography, Table, TableCell, TableHead,
  TableRow, ImageList, AppBar, Chip, Collapse, MenuItem, TableBody, IconButton,
  Select, Paper, Toolbar, TableContainer, TextField, CircularProgress, Fab
} from "@mui/material";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddActivityDialog from "../dialogs/AddActivityDialog";
import {DescriptionList, DescriptionTerm, Description} from "../parts/Description.parts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BasicAutocomplete from "../autocompletes/BasicAutocomplete";
import DownloadIcon from "@mui/icons-material/GetApp";
import {UserContext} from "../auth/UserProvider";
import * as XLSX from "xlsx";
import DumpLoadingIndicator from "../parts/DumpLoadingIndicator";

const styles = () => ({
  contentWrapper: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  tableContainer: {
    margin: 'auto',
    marginTop: '2rem',
    marginLeft: '2rem',
    overflow: 'hidden',
  },
  tableHead: {
    background: '#F5F5F5',
  },
  fab: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  gridList: {
    paddingBottom: '1rem',
  },
  paper: {
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Row(props) {
  let { activity, fetchData, shouldOpen, removeHandler, state, obsrv,
    isAppUser = false, isSuperUser = false, isReadOnlyUser = false } = props;
  const [open, setOpen] = useState(shouldOpen);
  const classes = useRowStyles();
  const cancelRequest = useRef();
  const [distributionValue, setDistributionValue] = React.useState(1);
  const [improvementValue, setImprovementValue] = React.useState(1);
  const [stateActivity, setStateActivity] = useState(activity);
  const [activityBuffer, setActivityBuffer] = useState(activity);

  const updateActivityDataAndDispatch = async (field, value) => {
    try {
      setActivityBuffer({
        ...activityBuffer,
        [field]: value,
      });
      const { data: activityData } = await updateActivity(
          activity.id,
          { [field]: value }
      );

      if (!cancelRequest.current) {
        setStateActivity(activityData);
      }
    } catch (error) {
      setActivityBuffer(activityBuffer);
    }
  };

  const removeTargetValue = async (activityId, targetValueId) => {
    await deleteTargetValue(activityId, targetValueId);
    await fetchData(true);
  };

  const handleActivityTypeChange = async (event) => {
    await updateActivityDataAndDispatch('activityType', event.target.value);
    await fetchData(true);
  };

  return (
      <React.Fragment>
        <TableRow ref={obsrv} className={classes.root}>
          <TableCell style={{ paddingBottom: 0, border: "none" }} colSpan={7}>
            <strong>{stateActivity.name}</strong>
          </TableCell>
        </TableRow>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{formatActivityType(stateActivity.activityType)}</TableCell>
          <TableCell>{formatTime(stateActivity.startDate, 'dd.LL.yyyy')}</TableCell>
          <TableCell>{stateActivity.location ? stateActivity.location.englishName : ''}</TableCell>
          <TableCell>
            {stateActivity.indicators && stateActivity.indicators.map((indicator) => (
                <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{indicator.name}</Typography>
                        {indicator.description}
                      </React.Fragment>
                    }
                >
                  <Chip size="small" style={{marginBottom: '0.2rem', marginRight: '0.8rem'}} label={truncate(indicator.name, 30)} />
                </HtmlTooltip>
            ))}
          </TableCell>
          <TableCell width={'15%'}>
            { state !== 'DELETED' && !isReadOnlyUser &&
              <ConfirmActionDialog action={'delete'} confirmationHandler={() => removeHandler(stateActivity.id)}/>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse style={{marginTop: '1rem', marginBottom: '1rem'}} in={open} timeout="auto" unmountOnExit>
              <Typography variant="h5" gutterBottom component="div">
                Activity details
              </Typography>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <AppBar className={classes.toolbar}
                        position="static"
                        color="default"
                        elevation={0}>
                  <Toolbar>
                    <Typography variant="h6" gutterBottom component="div">
                      General information
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={2}>
                  <DescriptionList>
                    <DescriptionTerm>Type</DescriptionTerm>
                    <Description>
                      <Select
                          labelId="activity-type-select"
                          id="activity-type-select"
                          value={stateActivity.activityType}
                          className={classes.selectEmpty}
                          onChange={handleActivityTypeChange}
                      >
                        <MenuItem value={'WORKSHOP'}>Workshop</MenuItem>
                        <MenuItem value={'MEETING'}>Meeting</MenuItem>
                        <MenuItem value={'CAMPAIGN'}>Campaign</MenuItem>
                      </Select>
                    </Description>
                  </DescriptionList>
                  </ImageList>
                  <ImageList cellHeight={'auto'} className={classes.gridList} cols={2}>
                    <DescriptionList>
                      <DescriptionTerm>Name</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="name"
                            value={stateActivity.name}
                            disabled={false}
                            fullWidth={true}
                            size={'large'}
                            multiline={true}
                            rows={4}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <br/>
                    <DescriptionList>
                      <DescriptionTerm>Location</DescriptionTerm>
                      <Description>
                        <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('location', value)} searchFct={searchLocations} currentValue={stateActivity.location} property={'englishName'} />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Organisation</DescriptionTerm>
                      <Description>
                        <BasicAutocomplete handleChange={(value) => updateActivityDataAndDispatch('organisation', value)} searchFct={searchOrganisations} currentValue={stateActivity.organisation} property={'name'} />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Region of the Activity</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="region"
                            value={stateActivity.region}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Country</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="country"
                            value={stateActivity.country}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Start date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="startDate"
                            type="date"
                            value={formatTime(stateActivity.startDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>End date</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="endDate"
                            type="date"
                            value={formatTime(stateActivity.endDate, 'dd.LL.yyyy')}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value + ' 00:00:00.000')}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Responsible</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="responsible"
                            value={stateActivity.responsible}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                    <DescriptionList>
                      <DescriptionTerm>Comments</DescriptionTerm>
                      <Description>
                        <EditableInput
                            name="comment"
                            value={stateActivity.comment}
                            disabled={false}
                            fullWidth={true}
                            onSave={(fieldName, value) => updateActivityDataAndDispatch(fieldName, value)}
                        />
                      </Description>
                    </DescriptionList>
                  </ImageList>
                </div>
              </Paper>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}

function Activities(props) {
  const { classes, state } = props;
  const [items, setItems] = useState([]);
  const pageToLoad = useRef(0);
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const [expanded, setExpanded] = React.useState('panel1');
  const [isLoadingDump, setIsLoadingDump] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const didMount = useRef(false);

  const authState = useContext(UserContext);

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: "50px 0px",
    // When the last item comes to the viewport
    onEnter: ({ unobserve, observe }) => {
      if (hasMore) {
        // Pause observe when loading data
        unobserve();
        // Load more data
        loadItems(false).then(observe());

        initialPageLoaded.current = true;
        pageToLoad.current = Number(pageToLoad.current) + 1;
      }

    },
  });

  const debouncedSetSearchTerm = useCallback(debounce(term => setSearchTerm(term), 500), []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const downloadActivityDump = (data) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push('Activity list');

    let ws_data = [
      ['Id', 'Type', 'Name', 'Location', 'Start date', 'End date',
        'Objective', 'Comments', 'Number of participants',
      'Female participants', 'Facilitator',
        'Organiser'],
    ];

    data.map(a => {
      ws_data.push([a.id, formatActivityType(a.activityType), a.name,
        a.location ? a.location.englishName : '', formatTime(a.date),
        formatTime(a.endDate), a.objective, a.comment, a.numberParticipants,
        a.numberFemaleParticipants, a.moderator,
        a.organizer]);
    });

    wb.Sheets['Activity list'] = XLSX.utils.aoa_to_sheet(
        ws_data);

    let date = new Date();

    XLSX.writeFile(wb,'activity_list.xlsx');
  };

  const fetchDump =
      async () => {
        setIsError(false);
        setIsLoadingDump(true);
        try {
          await getActivityDump()
          .then((response) => {
            setIsLoadingDump(false);
            if (response.data) {
              downloadActivityDump(response.data);
            }
          });
        } catch (error) {
          console.log(error);
        }
      };

  const loadItems = async (reset = false) => {
    const data = await getActivities(state, searchTerm, reset ? 0 : pageToLoad.current);
    setHasMore(data.data.totalPages > pageToLoad.current);
    setItems(reset ? data.data.content : prevItems => [...prevItems, ...data.data.content].reduce((unique, o) => {
      if(!unique.some(obj => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    },[]));

    if (reset) {
      pageToLoad.current = 0;
    }
  };

  const removeActivity = async (activityId, deleteType = 'SOFT') => {
    await deleteActivity(activityId, deleteType);
    await loadItems(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSearchTermChanged = (event) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const addActivityConfirmationHandler =
      async (activityType) => {
        await createActivity({
          'activityType': activityType,
        });
        await loadItems(true);
      };

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadItems(false);

    initialPageLoaded.current = true;
    pageToLoad.current = Number(pageToLoad.current) + 1;
  }, [loadItems]);

  useEffect(() => {
    if (didMount.current) {
      loadItems(true);
    }
    else didMount.current = true;
  }, [searchTerm]);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            { state !== 'DELETED' &&
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Try to add a new activity!
            </Typography>
            }
            { state === 'NEW' && authState.role !== 'ROLE_READER' &&
            <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
            }
          </div>
      );
    }

    if (isLoading || !items) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="secondary" />
          </div>
      );
    }

    if (isLoadingDump) {
      return (
          <DumpLoadingIndicator />
      );
    }

    return (
        <>
          <div className={classes.contentWrapper} style={{width: '95%'}}>
            <TextField
                label="Name, type, location, date"
                id="activity-filter"
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleSearchTermChanged}
            />
          </div>
          <TableContainer style={{width: '95%'}} component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Indicator attribution</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).map((row, idx) => (
                    <Row
                        key={row.id}
                        activity={row}
                        fetchData={loadItems}
                        shouldOpen={items.length === 1}
                        removeHandler={removeActivity}
                        state={state}
                        obsrv={idx === items.length - 1 ? observe : null}
                        isAppUser={authState.role === 'ROLE_APP'}
                        isSuperUser={authState.role === 'ROLE_SUPER'}
                        isReadOnlyUser={authState.role === 'ROLE_READER'}
                    />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          { state === 'REVIEWED' &&
            <div hidden={authState && authState.role && authState.role !== 'ROLE_SUPER'}>
              <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={() => fetchDump()}
                  className={classes.fab}
                  style={{position: 'fixed', marginBottom: '1rem'}}
              >
                <DownloadIcon/>
              </Fab>
            </div>
          }
          { authState.role !== 'ROLE_READER' &&
            <AddActivityDialog classes={classes} confirmationHandler={addActivityConfirmationHandler}/>
          }
        </>
    );
  };

  return renderData();
}

export default withStyles(styles)(Activities);