import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import Navigator from './Navigator';
import ProtectedRoute from "./auth/ProtectedRoute";
import Header from "./Header";
import UserSettings from "./settings/UserSettings";
import Activities from "./activities/Activities";
import {ThemeProvider, withStyles} from "@mui/styles";
import {
  createTheme,
  CssBaseline,
  Hidden,
  Typography
} from "@mui/material";
import Indicators from "./indicators/Indicators";
import TabbedOutputIndicators from "./indicators/TabbedOutputIndicators";
import Activity from "./activities/Activity";
import {UserContext} from "./auth/UserProvider";
import LocationSettings from "./settings/LocationSettings";
import TabbedOutputActivities from "./activities/TabbedOutputActivities";
import Budgets from "./budgets/Budgets";
import Dashboard from "./dashboard/Dashboard";

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
      </Typography>
  );
}

let theme = createTheme({
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2, 1),
    background: '#eaeff1',
  },
  mainTabbed: {
    flex: 1,
    background: '#eaeff1',
    padding: 0,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};

function Paperbase(props) {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const authState = useContext(UserContext);

  const query = new URLSearchParams(document.location.search);

  return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <BrowserRouter>
              <Switch>
                <ProtectedRoute exact path={["/dashboard/:tab", "/"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline="Dashboard"
                          showNavBar={false}
                          elevate={0}  />
                  <main className={classes.mainTabbed}>
                    <Dashboard org={authState.org} />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path="/activities">
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Activities"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <Activities state={'ALL'} />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path="/output-activities">
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Output Activities"}
                          showNavBar={false}
                          elevate={0}  />
                  <main className={classes.mainTabbed}>
                    <TabbedOutputActivities />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/activity/:activityId"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Activities"}
                          showNavBar={false} />
                  <main className={classes.main}>
                    <Activity/>
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/budgets"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Budgets"}
                          showNavBar={false} />
                  <main className={classes.main}>
                    <Budgets />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/indicators/specific"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Specific objectives"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <Indicators indicatorType="SPECIFIC_OBJECTIVE" />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/indicators/output"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Output indicators"}
                          showNavBar={false}
                          elevate={0} />
                  <main className={classes.mainTabbed}>
                    <TabbedOutputIndicators />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/users"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Users"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <UserSettings />
                  </main>
                </ProtectedRoute>
                <ProtectedRoute exact path={["/locations"]}>
                  <Header onDrawerToggle={handleDrawerToggle}
                          headline={"Locations"}
                          showNavBar={false}  />
                  <main className={classes.main}>
                    <LocationSettings />
                  </main>
                </ProtectedRoute>
                <Route path='/authentication' component={() => {
                  window.location.href =
                      `${process.env.REACT_APP_FIREBASE_REDIRECT_URL}?mode=${query.get('mode')}&oobCode=${query.get('oobCode')}&apiKey=${query.get('apiKey')}&lang=${query.get('lang')}`;
                  return null;
                }}/>
              </Switch>
            </BrowserRouter>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
      </ThemeProvider>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);