export function formatActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'WORKSHOP': return 'Workshop';
    case 'MEETING': return 'Meeting';
    case 'CAMPAIGN': return 'Campaign';
    default:
      return '';
  }
}

export function getInTextActivityType(rawType) {
  if (!rawType) {
    return null;
  }

  switch (rawType) {
    case 'WORKSHOP': return 'workshop';
    case 'MEETING': return 'meeting';
    case 'CAMPAIGN': return 'campaign';
    default:
      return '';
  }
}