export function truncate(str, n) {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

export function formatCurrencyValue(num) {
  if (num === undefined) num = 0;
  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}