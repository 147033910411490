import { DateTime } from 'luxon';
import i18next from 'i18next';

const timeZone = 'Europe/Berlin';
const defaultFormat = 'dd.LL.yyyy';

export function formatTime(date, formatStr = defaultFormat) {
  if (!date) {
    return null;
  }
  const sqlDate = DateTime.fromSQL(date, { zone: 'utc' });

  return sqlDate
  .setZone(timeZone)
  .setLocale(i18next.language)
  .toFormat(formatStr);
}

export function formatQuarter(date) {
  if (!date) {
    return null;
  }

  let year = new Date(date).getFullYear();

  if (new Date(date).getMonth() === 2) return `1/${year}`;
  if (new Date(date).getMonth() === 5) return `2/${year}`;
  if (new Date(date).getMonth() === 8) return `3/${year}`;
  if (new Date(date).getMonth() === 11) return `4/${year}`;
}

export function getQuarter(date) {
  if (!date) {
    return null;
  }

  if (new Date(date).getMonth() === 2) return 1;
  if (new Date(date).getMonth() === 5) return 2;
  if (new Date(date).getMonth() === 8) return 3;
  if (new Date(date).getMonth() === 11) return 4;
}