import styled from 'styled-components';

export const DescriptionList = styled.dl`
  margin: 0;
`;

export const DescriptionTerm = styled.dt`
  font-weight: 'bold';
  margin-bottom: 10px;
`;

export const Description = styled.dd`
  margin: 0;
`;