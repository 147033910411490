import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import {
  addIndicatorValue, createIndicator, deleteIndicator,
  deleteIndicatorValue,
  getIndicators, updateIndicator, updateIndicatorValue
} from "../../utils/api";
import {Chip, CircularProgress} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatTime} from "../../utils/timeHelper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ConfirmActionDialog from "../dialogs/ConfirmActionDialog";
import AddIndicatorValueDialog from "../dialogs/AddIndicatorValueDialog";
import CompleteIcon from "@mui/icons-material/Verified";
import IncompleteIcon from "@mui/icons-material/Cancel";
import {UserContext} from "../auth/UserProvider";

const styles = () => ({
  paper: {
    maxWidth: '80%',
    minWidth: '70%',
    margin: 'auto',
    marginTop: '2rem',
    overflow: 'hidden',
  },
  toolbar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px 16px',
  },
});

const arrSum = arr => arr.filter(v => v.numericValue !== undefined).reduce((a, b) => a + b.numericValue, 0);
const arrSumSecondary = arr => arr.filter(v => v.secondaryNumericValue !== undefined).reduce((a, b) => a + b.secondaryNumericValue, 0);
const arrSumTertiary = arr => arr.filter(v => v.tertiaryNumericValue !== undefined).reduce((a, b) => a + b.tertiaryNumericValue, 0);

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel);

function LinearProgressWithLabel(props) {
  const { value, current, target, classes } = props;

  return (
      <Box display="flex" alignItems="center">
        <Box width="90%" mr={1}>
          <LinearProgress sx={{height: 24}} color={'secondary'} variant="determinate" value={value > 100 ? 100 : value} classes={classes} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary"><strong>{`${value}%`}</strong></Typography>
          <Typography variant="body2" color="textSecondary">
            {current} / {target}
          </Typography>
        </Box>
      </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function IndicatorPaper(props) {
  const { classes, indicator, confirmationHandler, removeValueHandler, updateValueHandler, isEu = false } = props;

  const [organisation, setOrganisation] = useState('');

  const authState = useContext(UserContext);

  const handleOrganisationClicked = (e) => {
    console.log(e.currentTarget.innerText);
    if (organisation === e.currentTarget.innerText) {
      setOrganisation('');
    } else {
      setOrganisation(e.currentTarget.innerText);
    }
  }

  const checkOrganisationFilter = (iVal) => {
    if (organisation === '') return true;
    return (iVal.organisation && iVal.organisation.name === organisation);
  }

  return <Paper className={classes.paper}>
    <AppBar className={classes.toolbar}
            position="static"
            color="default"
            elevation={0}>
      <Toolbar>
        <Typography variant="h2" gutterBottom component="div">
          {indicator.name}
        </Typography>
        <div style={{position: 'absolute', right: '1rem', top: '0.2rem', display: 'flex', flexDirection: 'row'}}>
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography variant="body1" color="textSecondary">
        {indicator.description}
      </Typography>
    </div>

    { indicator.targetValueNumeric === 1 && indicator.indicatorValues.length !== 0 && arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)) === indicator.indicatorValues.length &&
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '16px 16px',
      color: 'green'
    }}>
      <span>Indicator complete</span>
      <CompleteIcon sx={{marginLeft: '0.5rem'}} />
    </div>
    }
    { indicator.targetValueNumeric === 1 && (indicator.indicatorValues.length === 0 || arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)) !== indicator.indicatorValues.length) &&
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '16px 16px',
      color: 'orange'
    }}>
      <span>Indicator is not yet complete</span>
      <IncompleteIcon sx={{marginLeft: '0.5rem'}} />
    </div>
    }
    {indicator.targetValueNumeric !== 1 &&
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary">
          {indicator.targetValueDescription}
        </Typography>
        <StyledLinearProgress
            value={indicator.startValueNumeric > indicator.targetValueNumeric
                ? 100 : Math.round((arrSum(indicator.indicatorValues.filter(checkOrganisationFilter)))
                    / (indicator.targetValueNumeric - indicator.startValueNumeric)
                    * 100)}
            current={indicator.startValueNumeric > indicator.targetValueNumeric
                ? indicator.startValueNumeric.toLocaleString() : arrSum(
                    indicator.indicatorValues.filter(checkOrganisationFilter)).toLocaleString()}
            target={indicator.startValueNumeric > indicator.targetValueNumeric
                ? indicator.targetValueNumeric.toLocaleString() : (Math.round(
                    (indicator.targetValueNumeric - indicator.startValueNumeric)
                    * 100) / 100).toLocaleString()} isEu={isEu}/>
      </div>
    }
    { indicator.targetValueNumeric !== 1 && indicator.secondaryTargetValueNumeric > 0 && indicator.secondaryStartValueNumeric >= 0 &&
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary">
          {indicator.secondaryTargetValueDescription}
        </Typography>
        <StyledLinearProgress
            value={Math.round((arrSumSecondary(indicator.indicatorValues.filter(checkOrganisationFilter))) / (indicator.secondaryTargetValueNumeric - indicator.secondaryStartValueNumeric) * 100)}
            current={arrSumSecondary(indicator.indicatorValues.filter(checkOrganisationFilter)).toLocaleString()}
            target={(indicator.secondaryTargetValueNumeric - indicator.secondaryStartValueNumeric).toLocaleString()} isEu={isEu}/>
      </div>
    }
    { indicator.targetValueNumeric !== 1 && indicator.tertiaryTargetValueNumeric > 0 && indicator.tertiaryStartValueNumeric >= 0 &&
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary">
          {indicator.tertiaryTargetValueDescription}
        </Typography>
        <StyledLinearProgress
            value={Math.round((arrSumTertiary(indicator.indicatorValues.filter(checkOrganisationFilter))) / (indicator.tertiaryTargetValueNumeric - indicator.tertiaryStartValueNumeric) * 100)}
            current={arrSumTertiary(indicator.indicatorValues.filter(checkOrganisationFilter)).toLocaleString()}
            target={(indicator.tertiaryTargetValueNumeric - indicator.tertiaryStartValueNumeric).toLocaleString()} isEu={isEu}/>
      </div>
    }
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width={'25%'}>Description</TableCell>
            <TableCell width={'10%'}>Date</TableCell>
            <TableCell width={'25%'}>Comments</TableCell>
            <TableCell width={'25%'}>Organisation</TableCell>
            { indicator.targetValueNumeric !== 1 &&
              <TableCell width={'5%'}>Value</TableCell>
            }
            { indicator.targetValueNumeric === 1 &&
                <TableCell width={'5%'}>Completed</TableCell>
            }
            <TableCell width={'10%'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {indicator.indicatorValues.filter(checkOrganisationFilter).map((indicatorValue) => (
              <TableRow key={indicatorValue.id}>
                <TableCell width={'30%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.description}</TableCell>
                <TableCell width={'10%'}>{formatTime(indicatorValue.creationDate, 'dd.LL.yyyy')}</TableCell>
                <TableCell width={'25%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.comment}</TableCell>
                <TableCell width={'20%'}
                           style={{
                             whiteSpace: "normal",
                             wordBreak: "break-word"
                           }}>{indicatorValue.organisation ? <Chip label={indicatorValue.organisation.name} onClick={handleOrganisationClicked}/> : null}</TableCell>
                {indicator.targetValueNumeric !== 1 &&
                  <TableCell
                      width={'5%'}><strong>{indicatorValue.numericValue.toLocaleString()}</strong> {indicatorValue.secondaryNumericValue
                      ? `/ ${indicatorValue.secondaryNumericValue.toLocaleString()}`
                      : ''} {indicatorValue.tertiaryNumericValue
                      ? `/ ${indicatorValue.tertiaryNumericValue.toLocaleString()}`
                      : ''}</TableCell>
                }
                {indicator.targetValueNumeric === 1 && indicatorValue.numericValue === 1 &&
                    <TableCell width={'5%'} style={{color: 'green'}}><CompleteIcon /></TableCell>
                }
                {indicator.targetValueNumeric === 1 && indicatorValue.numericValue === 0 &&
                    <TableCell width={'5%'} style={{color: 'orange'}}><IncompleteIcon /></TableCell>
                }
                <TableCell width={'10%'}>
                  {authState.role !== 'ROLE_READER' &&
                  <div style={{display: 'flex'}}>
                    <AddIndicatorValueDialog action={'edit'}
                                             indicator={indicator}
                                             entity={indicatorValue}
                                             confirmationHandler={updateValueHandler}/>
                    <ConfirmActionDialog action="delete"
                                         confirmationHandler={() => removeValueHandler(
                                             indicator.id, indicatorValue.id)}/>
                  </div>
                  }
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div className={classes.contentWrapper}>
      {authState.role !== 'ROLE_READER' &&
      <AddIndicatorValueDialog indicator={indicator}
                               confirmationHandler={confirmationHandler}/>
      }
    </div>
  </Paper>
}

function Indicators(props) {
  const { classes, indicatorType, isEu= false } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [indicators, setIndicators] = useState([]);

  const cancelRequest = useRef();

  const removeIndicator = async (indicatorId) => {
    await deleteIndicator(indicatorId);
    await fetchData();
  };

  const removeIndicatorValue = async (indicatorId, indicatorValueId) => {
    await deleteIndicatorValue(indicatorId, indicatorValueId);
    await fetchData();
  };

  const indicatorValueConfirmationHandler =
      async (indicatorId, description, activity, comment, organisation,
             numericValue, secondaryNumericValue, tertiaryNumericValue) => {
        await addIndicatorValue(indicatorId, {
          'description': description,
          'activity': activity,
          'comment': comment,
          'organisation': organisation,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        });
        await fetchData()
      };

  const indicatorConfirmationHandler =
      async (depth, name, abbreviation, description, prjct, startValue,
          targetValue, targetValueDescription, secondaryStartValue, secondaryTargetValue,
          secondaryDescription, tertiaryStartValue, tertiaryTargetValue,
          tertiaryDescription) => {
        let body = {
          'indicatorType': indicatorType,
          'name': name,
          'abbreviation': abbreviation,
          'description': description,
          'projects' : [
            prjct
          ],
          'startValueNumeric': startValue,
          'targetValueNumeric': targetValue,
          'targetValueDescription': targetValueDescription,
        }

        if (depth > 0 && secondaryTargetValue > 0) {
          body['secondaryStartValueNumeric'] = secondaryStartValue;
          body['secondaryTargetValueNumeric'] = secondaryTargetValue;
          body['secondaryTargetValueDescription'] = secondaryDescription;

          if (depth > 1 && tertiaryTargetValue > 0) {
            body['tertiaryStartValueNumeric'] = tertiaryStartValue;
            body['tertiaryTargetValueNumeric'] = tertiaryTargetValue;
            body['tertiaryTargetValueDescription'] = tertiaryDescription;
          }
        }

        await createIndicator(body);
        await fetchData();
      };

  const updateConfirmationHandler =
      async (entityId, depth, name, abbreviation, description, prjct, startValue,
          targetValue, targetValueDescription, secondaryStartValue, secondaryTargetValue,
          secondaryDescription, tertiaryStartValue, tertiaryTargetValue,
          tertiaryDescription) => {
        let body = {
          'indicatorType': indicatorType,
          'name': name,
          'abbreviation': abbreviation,
          'description': description,
          'projects' : [
            prjct
          ],
          'startValueNumeric': startValue,
          'targetValueNumeric': targetValue,
          'targetValueDescription': targetValueDescription,
        }

        if (depth > 0 && secondaryTargetValue > 0) {
          body['secondaryStartValueNumeric'] = secondaryStartValue;
          body['secondaryTargetValueNumeric'] = secondaryTargetValue;
          body['secondaryTargetValueDescription'] = secondaryDescription;

          if (depth > 1 && tertiaryTargetValue > 0) {
            body['tertiaryStartValueNumeric'] = tertiaryStartValue;
            body['tertiaryTargetValueNumeric'] = tertiaryTargetValue;
            body['tertiaryTargetValueDescription'] = tertiaryDescription;
          }
        }

        await updateIndicatorDataAndDispatch(entityId, body);
        await fetchData();
      };

  const confirmIndicatorValueUpdate =
      async (indicatorValueId, indicatorId, description, activity, comment, organisation,
          numericValue, secondaryNumericValue, tertiaryNumericValue) => {
        await updateIndicatorValueDataAndDispatch(indicatorId, indicatorValueId, {
          'description': description,
          'activity': activity,
          'comment': comment,
          'organisation': organisation,
          'numericValue': +numericValue,
          'secondaryNumericValue': +secondaryNumericValue,
          'tertiaryNumericValue': +tertiaryNumericValue
        });
        await fetchData();
      };

  const updateIndicatorDataAndDispatch = async (indicatorId, fields) => {
    try {
      await updateIndicator(
          indicatorId,
          fields
      );
    } catch (error) {
    }
  };


  const updateIndicatorValueDataAndDispatch = async (indicatorId, indicatorValueId, fields) => {
    try {
      await updateIndicatorValue(
          indicatorId,
          indicatorValueId,
          fields
      );
    } catch (error) {
    }
  };

  const fetchData =
      async loading => {
        setIsError(false);
        setIsLoading(loading);
        try {
          const result = await getIndicators();
          const indicatorData = result.data;

          if (!cancelRequest.current) {
            if (indicatorData && Object.keys(indicatorData).length > 0) {
              console.log(indicatorData);
              setIndicators(indicatorData);
            } else {
              setIsError(true);
            }
          }
        } catch (error) {
          if (!cancelRequest.current) {
            setIsError(true);
          }
        }
      };

  useEffect(() => {
    fetchData(false);
  }, []);

  const renderData = () => {
    if (isError) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <Typography variant="h6" color="primary" style={{marginTop: '10%'}}>
              Unable to fetch indicators or no indicators were added in this category!
            </Typography>
          </div>
      );
    }

    if (isLoading || !indicators) {
      return (
          <div style={{width: '100%', textAlign: 'center', height: '100%'}}>
            <CircularProgress style={{marginTop: '10%'}} color="secondary" />
          </div>
      );
    }

    return (
        <>
          { indicators.filter(i => i.indicatorType === indicatorType).map((indicator) => {
            return <IndicatorPaper key={indicator.id} classes={classes}
                                   indicator={indicator}
                                   updateHandler={updateConfirmationHandler}
                                   confirmationHandler={indicatorValueConfirmationHandler}
                                   removeHandler={removeIndicator}
                                   removeValueHandler={removeIndicatorValue}
                                   updateValueHandler={confirmIndicatorValueUpdate} isEu={isEu}/>
          })}
        </>
    );
  };

  return renderData();
}

Indicators.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Indicators);
